.services {
  margin-top: $base-size * 4;

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    gap: $base-size * 5;
  }
  &__container {
    margin-bottom: $base-size * 10;
  }
  &__title {
    @include font-h1;
    margin-top: $base-size * 2;

    @media (max-width: $max-l) {
      margin-top: $base-size * 3;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin-top: 0;
      margin-bottom: $base-size * 4;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $base-size * 4;

    @media (max-width: $max-l) {
      gap: $base-size * 2;
    }
    @media (max-width: $max-m) {
      display: flex;
      flex-direction: column;
    }
  }
  &__item {
    background: radial-gradient(
        109.56% 236.76% at 91.99% 109.56%,
        rgba(255, 255, 255, 0.2) 14.24%,
        rgba(68, 215, 179, 0.2) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border-radius: $base-size * 4;
    overflow: hidden;
    padding: $base-size * 5;
    gap: $base-size * 4;

    display: flex;
    flex-direction: column;

    @media (max-width: $max-l) {
      padding: $base-size * 3;
      gap: $base-size * 3;
    }
    @media (max-width: $max-m) {
      padding: $base-size * 2;
      gap: $base-size * 2;
    }

    &-title {
      @include font-h2;
      flex: 0 0 auto;

      @media (max-width: $max-l) {
        @include font-h3;
      }
      @media (max-width: $max-m) {
        @include font-h4;
      }
    }
    &-desc {
      @include text-m-regular;
      flex: 1 0 auto;
      margin-bottom: $base-size * 2;

      @media (max-width: $max-l) {
        @include text-s-regular;
      }
      @media (max-width: $max-m) {
        @include text-xs-regular;
        margin-bottom: $base-size * 4;
      }
    }
    &-button {
      flex: 0 0 auto;

      & .button {
        @media (max-width: $max-l) {
          display: flex;
        }

        @media (max-width: $max-m) {
          @include button-size($button-height-m);
          color: $color-green-primary;
          padding-left: $base-size * 2.5;

          &:hover {
            color: $color-green-hover;
          }
        }
      }
    }
  }
}

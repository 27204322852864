/*===============================
=            Choices            =
===============================*/

$choices-selector: 'choices' !default;
$choices-font-size-lg: 16px !default;
$choices-font-size-md: 14px !default;
$choices-font-size-sm: 12px !default;
$choices-guttering: 24px !default;
$choices-border-radius: 2.5px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #f9f9f9 !default;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: #ffffff !default;
$choices-text-color: #333333 !default;
$choices-keyline-color: #dddddd !default;
$choices-primary-color: #00bcd4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;
$choices-icon-cross: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==) !default;
$choices-icon-cross-inverse: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==) !default;

$item-height: 72px;
$dropdown-item-height: 28px;

.choices {
  position: relative;
  margin-bottom: $choices-guttering;
  z-index: 10;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.is-disabled {
    .choices__inner,
    .choices__input {
      background-color: $choices-bg-color-disabled;
      cursor: not-allowed;
      user-select: none;
    }
    .choices__item {
      cursor: not-allowed;
    }
  }

  [hidden] {
    display: none !important;
  }

  &--form {
    & .choices__inner {
      box-shadow: none;
      padding: $base-size * 1.75 0;
      border-bottom: 1px solid $color-gray-4;
      border-radius: 0;

      &:after {
        width: $base-size * 3;
        height: $base-size * 3;
        // background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7L8 11L12 7' stroke='%2390929A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icn_chevron-down_24'%3E%3Cpath id='Path 3' d='M6 9L12 15L18 9' stroke='%2390929A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      & .choices__item {
        @include text-s-regular;
        color: $color-gray-3;
      }
    }
    & .choices__list--dropdown .choices__placeholder {
      display: none;
    }
  }
}

.choices[data-type*='select-one'] {
  cursor: pointer;
  .choices__inner {
    // padding-bottom: 7.5px;
  }
  .choices__input {
    display: block;
    width: 100%;
    // padding: 10px;
    border-bottom: 1px solid $choices-keyline-color;
    background-color: #ffffff;
    margin: 0;
  }
  .choices__button {
    background-image: $choices-icon-cross-inverse;
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5;
    &:hover,
    &:focus {
      opacity: 1;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px $choices-highlight-color;
    }
  }
  .choices__item[data-value=''] .choices__button {
    display: none;
  }
}

.choices[data-type*='select-multiple'],
.choices[data-type*='text'] {
  .choices__inner {
    cursor: text;
  }
  .choices__button {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-right: -$choices-button-offset/2;
    margin-bottom: 0;
    margin-left: $choices-button-offset;
    padding-left: $choices-button-offset * 2;
    border-left: 1px solid darken($choices-primary-color, 10%);
    background-image: $choices-icon-cross;
    background-size: $choices-button-dimension;
    width: $choices-button-dimension;
    line-height: 1;
    opacity: 0.75;
    border-radius: 0;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.choices__inner {
  display: flex;
  gap: $base-size;
  align-items: center;
  width: 100%;
  transition: border-color var(--transition);

  border-radius: $base-size;
  padding: 12px;
  box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);

  overflow: hidden;

  &:hover {
    // border-color: rgba($color-black, 0.8);
  }

  .is-focused &,
  .is-open & {
    // border-color: rgba($color-black, 0.8);
  }
  .is-open & {
    // border-radius: $choices-border-radius $choices-border-radius 0 0;
  }
  .is-flipped.is-open & {
    // border-radius: 0 0 $choices-border-radius $choices-border-radius;
  }

  &:after {
    content: '';
    height: $base-size * 2;
    width: $base-size * 2;
    flex: 0 0 auto;

    // position: absolute;
    right: $base-size * 1.5;
    pointer-events: none;
    background-image: $chevron-down-2;
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform var(--transition);
  }
  .choices.is-open &:after {
    // border-color: transparent transparent $choices-text-color transparent;
    // margin-top: -7.5px;
    transform: rotate(180deg);
  }
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;

  @media (max-width: $max-m) {
    padding-top: 0.2em;
  }
}

.choices__list--single {
  display: block;
  // padding: 4px 16px 4px 4px;
  width: 100%;

  .choices__item {
  }
}

.choices__list--multiple {
  display: inline;
  .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: $choices-border-radius-item;
    // padding: 4px 10px;
    // font-size: $choices-font-size-sm;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: $choices-primary-color;
    border: 1px solid darken($choices-primary-color, 5%);
    color: #ffffff;
    // word-break: break-all;
    box-sizing: border-box;
    &[data-deletable] {
      padding-right: 5px;
    }

    &.is-highlighted {
      background-color: darken($choices-primary-color, 5%);
      border: 1px solid darken($choices-primary-color, 10%);
    }
    .is-disabled & {
      background-color: darken($choices-disabled-color, 25%);
      border: 1px solid darken($choices-disabled-color, 35%);
    }
  }
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  min-width: 300px;
  background-color: $color-white;
  top: 100%;
  margin-top: $base-size;
  overflow: hidden;
  will-change: visibility, opacity, transform;

  border-radius: $base-size;

  box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);
  padding: $base-size * 2.5 $base-size * 2;
  opacity: 0;
  transition: all var(--transition);

  &.is-active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  .is-open & {
    border-color: darken($choices-keyline-color, 15%);
  }
  .is-flipped & {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
    transform: translateY(20px);
  }
  .choices__list {
    position: relative;
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;

    display: flex;
    flex-direction: column;
    gap: $base-size;
  }
  .choices__item {
    position: relative;
    color: $color-gray-2;
    // padding: 10px;
    // font-size: $choices-font-size-md;

    // white-space: nowrap;
  }
  .choices__item--selectable {
    @media (min-width: 640px) {
      &:after {
        content: attr(data-select-text);
        // font-size: $choices-font-size-sm;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      [dir='rtl'] & {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px;
        &:after {
          right: auto;
          left: 10px;
        }
      }
    }
    &.is-highlighted,
    &.is-selected {
      // background-color: rgba($color-black, 0.05);
      color: $color-black;
      &:after {
        opacity: 0.5;
      }
    }
  }
}

.choices__item {
  cursor: default;
  @include text-xs-regular // line-height: $item-height;
    // padding: 0 28px;
;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  // font-size: $choices-font-size-sm;
  padding: 10px;
  border-bottom: 1px solid lighten($choices-keyline-color, 10%);
  color: lighten(#333, 30%);
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: $choices-bg-color;
  // font-size: $choices-font-size-md;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
  &:focus {
    outline: 0;
  }
}

.choices__placeholder {
  // opacity: 0.5;
}

/*=====  End of Choices  ======*/

.checkbox {
  display: flex;
  align-items: center;
  height: 45px;

  &__label {
    display: flex;
    align-items: center;
    @include text-xs-regular;
    color: $color-gray-1;
    gap: $base-size;
    cursor: pointer;

    user-select: none;

    &:before {
      content: '';
      display: inline-block;
      height: $base-size * 3;
      width: $base-size * 3;
      border: none;
      box-shadow: 0 $base-size * 1.5 $base-size * 3.75 3px rgba(15, 71, 88, 0.08);
      border-radius: $base-size / 2;
      background:
        url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="#3d9d6f" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
          no-repeat center,
        white;
      background-size: 0;
      transform: translate3d(0, 0, 0);
      transition:
        color 0.1s,
        border 0.1s,
        background 0.15s,
        box-shadow 0.1s;
    }
  }

  &__input {
  }

  &__text {
    transform: translateY(1px);

    .checkbox__label:hover & {
      opacity: 0.8;
    }
  }

  & *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  &__input {
    margin: 0;
    width: 0;
    height: 0;
    display: inline;
    appearance: none;

    + label {
    }

    &:enabled:active + label:before,
    &:enabled + label:active:before {
      background-color: $color-gray-6;
    }

    &:checked + label:before {
      background-size: 0.75em;
    }

    &:checked:enabled:active + label:before,
    &:checked:enabled + label:active:before {
    }

    &:focus + label:before {
    }

    &:focus:active + label:before,
    &:focus + label:active:before {
    }

    &:disabled + label:before {
      opacity: 0.5;
    }
  }
}

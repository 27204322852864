.index-5 {
  display: flex;
  flex-direction: column;
  gap: $base-size * 8;
  position: relative;

  @media (max-width: $max-l) {
    margin-bottom: $base-size * 12;
  }
  @media (max-width: $max-m) {
    margin-top: $base-size * 4;
    margin-bottom: $base-size * 6.25;
  }

  &__top {
    @include container-content-grid;

    @media (max-width: $max-l) {
      gap: $base-size * 3;
    }
  }
  &__subtitle {
    grid-column: 1 / 3;
    @include font-h6;
    color: $color-red;

    @media (max-width: $max-xl) {
      grid-column: 1/4;
    }
    @media (max-width: $max-l) {
      grid-column: 1 / -1;
      @include text-l-medium;
    }
    @media (max-width: $max-m) {
      @include font-h6;
    }
  }
  &__title {
    @include font-h2;
    grid-column: 3 / -1;

    @media (max-width: $max-xl) {
      grid-column: 4 / -1;
    }
    @media (max-width: $max-l) {
      grid-column: -1 / 1;
      @include font-h3;
    }
    @media (max-width: $max-m) {
      @include font-h5;
    }
  }
  &__content {
    @include container-content-grid;
    position: relative;
    padding-top: $base-size * 7;

    @media (max-width: $max-l) {
      padding-top: $base-size * 3;
    }

    &-left {
      grid-column: 2 / 7;
      display: grid;
      grid-template-columns: subgrid;
      z-index: 1;
      position: relative;
      z-index: 2;

      @media (max-width: $max-xl) {
        grid-column: 1 / -1;
      }
    }
    &-right {
      grid-column: 7 / -1;
      z-index: 1;

      @media (max-width: $max-xl) {
        display: none;
      }
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    height: 510px;
    user-select: none;

    @media (max-width: $max-l) {
      left: -$base-size * 2;
      height: 374px;
    }

    @media (max-width: $max-m) {
      // width: 1444px;
      height: 520px;
      top: -$base-size * 2;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media (max-width: $max-m) {
        width: auto;
        max-width: none;
      }
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

/*
  $minSize,
  $maxSize,
  $minView,
  $maxView,
  $dimension - w or h for vw or vh

*/
@function responsive-value(
  $minSize,
  $maxSize,
  $minView: $mobile-min-width,
  $maxView: $fhd-width,
  $dimension: 'w'
) {
  @return calc(
    #{$minSize} + (#{strip-unit($maxSize)} - #{strip-unit($minSize)}) * (
        100v#{$dimension} - #{$minView}
      ) / (#{strip-unit($maxView)} - #{strip-unit($minView)})
  );
}

// calculate fraction of size against fullhd width or height
@function percent-view($size, $maxView: $fhd-width, $dimension: 'w') {
  $result: strip-unit($size) / strip-unit($maxView) * 100;
  @return $result + v + $dimension;
}

// calculate size between min and max. Max is based on fullhd width and height. Min is based on mobile max width and height
@function responsive-fhd($minSize, $maxSize) {
  @return calc(
    #{responsive-value(
        $minSize / 2,
        $maxSize / 2,
        $mobile-min-width,
        $fhd-width,
        'w'
      )} + #{responsive-value(
        $minSize / 2,
        $maxSize / 2,
        $mobile-min-height,
        $fhd-height,
        'h'
      )}
  );
}

// calculates column width as fraction of 100% minus column gaps
@function percent-column($columns, $gap) {
  @return calc((100% - (#{$columns} - 1) * #{$gap}) / #{$columns});
}

@function columns($columns, $gap: $base-size * 2) {
  @return calc(
    (#{$column-width-px} * #{$columns}) + ((#{$columns} - 1) * #{$gap})
  );
}
@function padding-border($padding, $border-width) {
  @return $padding - $border-width;
}

@function calc-container-grid($side-margin, $grid-gap) {
  @return ($side-margin - $grid-gap) repeat(12, minmax(0, 1fr))
    ($side-margin - $grid-gap);
}

@function calc-container-grid-16($side-margin, $grid-gap) {
  @return ($side-margin - $grid-gap) repeat(16, minmax(0, 1fr))
    ($side-margin - $grid-gap);
}

.footer {
  background-color: $color-black;
  @include text-xs-regular;

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  & a {
    color: rgba($color-white, 0.5);

    &:hover,
    &:focus,
    &.active {
      color: $color-white;
    }
    &:active {
      color: rgba($color-white, 0.8);
    }
  }

  &__container {
    @include container;

    @media (max-width: $max-m) {
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: $base-size * 8;
    padding: $base-size * 8 0 $base-size * 6;
    overflow: hidden;

    @media (max-width: $max-l) {
      padding-top: $base-size * 6;
      gap: $base-size * 6;
    }
  }
  &__bottom {
    width: 100%;
    padding: $base-size * 5 0;

    @media (max-width: $max-m) {
      padding: 0;
    }

    &-left {
      grid-column: 2 / 5;
      display: flex;
      flex-direction: column;
      gap: $base-size * 3;
      padding: 0 ($base-size * 4) 0 0;

      @media (max-width: $max-l) {
        grid-column: 2 / 7;
        padding: 0;
      }
      @media (max-width: $max-m) {
        grid-column: 2 / -2;
        gap: $base-size * 2.5;
        padding: $base-size * 5 0;
      }
    }
    &-right {
      grid-column: 5 / -2;
      margin-left: $base-size * -4;
      display: flex;
      flex-direction: column;
      gap: $base-size * 6;

      @media (max-width: $max-l) {
        grid-column: 8 / -2;
        margin-left: -$base-size * 2;
      }
      @media (max-width: $max-m) {
        grid-column: 2/-2;
        margin-left: 0;
        gap: $base-size * 3;
      }
    }
  }

  &__copyright {
    grid-row: 2 / 3;
    grid-column: 2 / -2;
    display: flex;
    flex-wrap: wrap;
    gap: $base-size * 6;
    padding: ($base-size * 5) 0;
    color: $color-gray-7;

    @media (max-width: $max-m) {
      padding: ($base-size * 5) 0 ($base-size * 3) 0;
      gap: $base-size * 1.5;
    }

    & > * {
      flex: 0 0 auto;

      @media (max-width: $max-m) {
        width: 100%;
        text-align: center;
      }
    }

    & a {
      color: $color-gray-7;
    }

    &-center {
      flex: 1 0 auto;
    }

    &-developer {
      justify-content: end;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: $base-size * 3;

    &-title {
      @include button-reset;
      pointer-events: none;

      color: $color-green-primary;
      @include text-xs-regular;

      @media (max-width: $max-m) {
        @include font-h4;
        cursor: pointer;
        pointer-events: all;
      }

      & > svg {
        display: none;
        @media (max-width: $max-m) {
          display: block;
          width: $base-size * 3;
          height: $base-size * 3;
          flex: 0 0 auto;
          transform: rotate(0deg);

          transition: transform var(--transition);

          .footer__menu.collapsed & {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-list {
      display: flex;
      gap: $base-size * 2;
      flex-wrap: wrap;

      transition:
        max-height 0.4s ease 0s,
        visibility 0s linear 0s;

      @media (max-width: $max-m) {
        // max-height: 500px;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: hidden;
        max-height: 0px;
      }

      .footer__menu.collapsed & {
        @media (max-width: $max-m) {
          // display: none;
          visibility: hidden;
          max-height: 0px;

          transition:
            max-height 0.4s ease 0s,
            visibility 0s linear 0.3s;
        }
      }
    }

    &-item {
      flex: 0 0 auto;

      &.active {
        color: $color-white;
      }

      & a {
        @include text-l-regular;
      }
    }
  }

  &__logo {
    user-select: none;
    & > img {
      @media (max-width: $max-m) {
        width: 170px;
      }
    }
  }

  &__phone-schedule-wrap {
    display: flex;
    flex-direction: column;
    @media (max-width: $max-m) {
      gap: $base-size * 2;
    }
  }
  &__phone {
    color: rgba($color-white, 0.5);
    @include text-m-medium;
  }
  &__schedule {
    color: rgba($color-white, 0.3);
  }
  .footer &__email {
    color: $color-green-primary;
  }
  &__address {
    @include text-xs-regular;
    color: rgba($color-white, 0.5);
  }
}

.burger {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  gap: $base-size * 2;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: opacity var(--transition);

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  &__button {
    display: block;
    overflow: visible !important;
    // position: relative;
    transition: all var(--transition);
    width: $base-size * 6;
    height: $base-size * 6;
    display: flex;
    align-items: center;

    & > span {
      display: block;
      height: 0px;
      width: 100%;
      position: relative;
      transition: all var(--transition);
      overflow: visible !important;

      &:after,
      &:before {
        position: absolute;
        display: block;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: $color-white;
        border-radius: 1px;
        content: '';
        transition-duration: 0.2s, 0.2s, 0.2s;
        transition-delay: 0.1s, 0s, 0s;
      }

      &:before {
        top: -6px;
        transition-property: top, transform, width;
      }
      &:after {
        bottom: -6px;
        transition-property: bottom, transform, width;
      }

      .header.header--open &,
      body.header--open & {
        &:before {
          top: -2px;
          transform: rotate(45deg);
          transition-delay: 0s, 0.15s;
        }

        &:after {
          bottom: 0;
          transform: rotate(-45deg);
          transition-delay: 0s, 0.15s;
        }
      }
    }
  }

  &__text {
    color: $color-white;
    position: relative;
    @include text-xs-regular;

    @media (max-width: $max-m) {
      display: none;
    }

    & sup {
      position: absolute;
      margin-left: $base-size / 2;
      font-size: $base-font-size * 1.1;
    }
  }
}

@mixin font-h1 {
  font-family: $font-family;
  font-size: 4.8 * $base-font-size;
  line-height: 1.2;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}

@mixin font-h2 {
  font-family: $font-family;
  font-size: 4 * $base-font-size;
  line-height: 1.2;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}

@mixin font-h3 {
  font-family: $font-family;
  font-size: 3.2 * $base-font-size;
  line-height: 1.3;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}

@mixin font-h4 {
  font-family: $font-family;
  font-size: 2.4 * $base-font-size;
  line-height: 1.2;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}
@mixin font-h5 {
  font-family: $font-family;
  font-size: 2 * $base-font-size;
  line-height: 1.2;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}
@mixin font-h6 {
  font-family: $font-family;
  font-size: 1.7 * $base-font-size;
  line-height: 1.2;
  font-weight: $font-weight-medium;
}

@mixin text-l-medium {
  font-family: $font-family;
  font-size: 2.2 * $base-font-size;
  line-height: 1.3;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}
@mixin text-l-regular {
  font-family: $font-family;
  font-size: 2.2 * $base-font-size;
  line-height: 1.3;
  font-weight: $font-weight-regular;
  letter-spacing: -0.02em;
}
@mixin text-m-medium {
  font-family: $font-family;
  font-size: 1.9 * $base-font-size;
  line-height: 1.3;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}
@mixin text-m-regular {
  font-family: $font-family;
  font-size: 1.9 * $base-font-size;
  line-height: 1.3;
  font-weight: $font-weight-regular;
  letter-spacing: -0.02em;
}
@mixin text-s-medium {
  font-family: $font-family;
  font-size: 1.7 * $base-font-size;
  line-height: 1.4;
  font-weight: $font-weight-medium;
  letter-spacing: -0.02em;
}
@mixin text-s-regular {
  font-family: $font-family;
  font-size: 1.7 * $base-font-size;
  line-height: 1.4;
  font-weight: $font-weight-regular;
  letter-spacing: -0.02em;
}
@mixin text-xs-medium {
  font-family: $font-family;
  font-size: 1.5 * $base-font-size;
  line-height: 1.4;
  font-weight: $font-weight-medium;
}
@mixin text-xs-regular {
  font-family: $font-family;
  font-size: 1.5 * $base-font-size;
  line-height: 1.4;
  font-weight: $font-weight-regular;
  letter-spacing: -0.02em;
}

@mixin font-body {
  font-size: 1.6 * $base-font-size;
  line-height: 1.55;
  font-weight: $font-weight-regular;

  @media (min-width: $breakpoint-small) {
    font-size: 1.8 * $base-font-size;
  }
}

@mixin transition-fix {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  rotate: 0.0001deg;
}

@mixin block-rounded {
  padding: $block-padding;
  background: $color-gray-2;
  border-radius: $border-radius;
}

@mixin animation-fade {
  opacity: 0;
  transition: all var(--transition);
}
@mixin animation-fade-active {
  opacity: 1;
}

@mixin align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin line-clamp($num) {
  -webkit-line-clamp: $num;
  line-clamp: $num;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin content-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $base-size * 4 $base-size * 2;

  @media (max-width: $max-m) {
    grid-template-columns: repeat(6, 1fr);
    gap: $base-size;
  }
}

@mixin button-reset {
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  padding: 0;
  appearance: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin section-bg {
  position: absolute;
  height: 606px;
  top: -$base-size * 2;
  left: -$base-size * 6;
  z-index: 0;
  pointer-events: none;

  & img {
    max-width: none;
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: left top;
  }
}


.logo {
  position: relative;
  display: block;
  user-select: none;

  & > img {
    transition: opacity var(--transition);
  }

  &__image {
    opacity: 1;

    .header--open & {
      opacity: 0;
    }
  }
  &__image-white {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    opacity: 0;

    .header--open & {
      opacity: 1;
    }
  }
}

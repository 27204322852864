.animation {
  &--left {
    transform: translateX(-20%);
    opacity: 0;
    transition: all var(--transition);

    &.on {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

@keyframes Fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes SlideFadeBottom {
  0% {
    transform: translateY(-#{$animation-translate});
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes SlideFadeTop {
  0% {
    transform: translateY(#{$animation-translate});
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes SlideFadeLeft {
  0% {
    transform: translateX(#{$animation-translate});
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes SlideFadeRight {
  0% {
    transform: translateX(-#{$animation-translate});
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes SlideTopStickyFix {
  0% {
    transform: translateY(#{$animation-translate});
    opacity: 0;
  }
  99% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: none;
  }
}
@keyframes SlideTop {
  0% {
    transform: translateY(#{$animation-translate});
  }
  to {
    transform: translateY(0);
  }
}

@keyframes SlideTop100 {
  0% {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes SlideRight100 {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes SlideLeft100 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes OutIn {
  0% {
    transform: translateX(0);
  }
  49% {
    transform: translateX(100%);
    opacity: 1;
  }
  50% {
    transform: translateX(100%);
    opacity: 0;
  }
  51% {
    transform: translateX(-100%);
    opacity: 0;
  }
  52% {
    transform: translateX(-100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

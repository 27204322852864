.index-3 {
  @include container-content-grid;

  @media (max-width: $max-l) {
    grid-template-columns: none;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    gap: $base-size * 2;
  }

  &__left {
    grid-column: span 6;
  }

  &__panel {
    padding: $base-size * 9 $base-size * 9.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $base-size * 4;
    height: 100%;
    justify-content: center;

    background: $color-gray-6;
    border-radius: $base-size * 4;
    text-align: center;

    @media (max-width: $max-xl) {
      padding: $base-size * 9 $base-size * 4;
    }

    @media (max-width: $max-l) {
      padding: $base-size * 9 $base-size * 11;
    }

    @media (max-width: $max-m) {
      padding: $base-size * 4 $base-size * 3;
    }

    &-title {
      @include font-h3;

      @media (max-width: $max-m) {
        @include font-h5;
      }
    }

    &-text {
      @include text-m-medium;
      color: $color-gray-1;

      @media (max-width: $max-m) {
        @include text-xs-regular;
      }
    }
  }

  &__right {
    grid-column: span 6;

    background: $color-black;
    border-radius: $base-size * 4;
    padding: $base-size * 4 $base-size * 3 $base-size * 4 $base-size * 4;

    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
    overflow: hidden;

    @media (max-width: $max-m) {
      padding: $base-size * 2.5;
    }

    &-title {
      @include font-h4;
      color: $color-white;
      width: 85%;
      z-index: 1;

      @media (max-width: $max-m) {
        padding-right: $base-size * 1.5;
        @include font-h6;
        width: 100%;
      }
    }
  }

  &-slider {
    position: relative;
    flex: 1 0 auto;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 626px;
      height: 626px;
      border-radius: 50%;

      opacity: 0.8;
      background: #cf4656;
      filter: blur(200px);
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
    }

    &__wrapper {
      height: 100%;
    }

    &__item {
      position: relative;
      height: 100%;

      &-wrap {
        display: block;
        height: 100%;
      }

      &-image {
        height: 422px;
        width: 546px;
        position: absolute;
        bottom: -33px;
        right: -25px;
        pointer-events: none;
        text-align: right;
        user-select: none;

        @media (max-width: $max-xl) {
          width: 100%;
          height: 387px;
          right: auto;
          bottom: auto;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: $max-l) {
          height: 440px;
          left: auto;
          right: 0;
          transform: translate(50px, -50%);
        }

        @media (max-width: $max-m) {
          position: relative;
          height: 342px;
          left: 0;
          top: 0;
          transform: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: -$base-size * 5;
        }

        & img {
          max-height: 100%;
          width: auto;
          height: 100%;
          object-fit: contain;
          object-position: bottom right;

          @media (max-width: $max-xl) {
            object-position: bottom;
          }

          @media (max-width: $max-m) {
            object-position: center;
            max-width: calc(100vw - #{$base-size * 1});
          }
        }
      }

      &-desc {
        position: absolute;
        display: flex;
        gap: $base-size * 2;
        align-items: center;
        width: 281px;
        bottom: 0;
        left: 0;
        background-color: rgba($color-white, 0.5);
        padding: $base-size $base-size * 2;
        color: $color-black;
        border-radius: $base-size * 1.5;
        @include text-xs-medium;
        backdrop-filter: blur(20px);
        transition: color var(--transition);
        box-sizing: border-box;

        @media (max-width: $max-m) {
          width: 100%;
        }

        .index-3-slider__item:hover & {
          color: rgba($color-black, 0.8);
        }

        & > svg {
          flex: 0 0 auto;
          width: $base-size * 3;
          height: $base-size * 3;
          stroke: $color-black;
          transition: stroke var(--transition);

          .index-3-slider__item:hover & {
            stroke: rgba($color-black, 0.8);
          }
        }
      }
    }

    &__nav-wrap {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
  }
}

.dropdown {
  &__parent {
    position: relative;

    &--filter {
      display: flex;
    }
  }

  &__sub {
    background: $color-white;
    border-radius: $base-size;
    padding: $base-size * 2 $base-size * 2.5;

    position: absolute;
    top: calc(100% + 0px);
    left: -$base-size * 2.5;
    z-index: 2;

    display: flex;
    flex-direction: column;
    gap: $base-size;

    box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);
    @include text-xs-regular;

    transition: all var(--transition);

    &--right {
      left: auto;
      right: 0;
    }

    .dropdown__parent--filter &,
    &--filter {
      left: 0;
      padding: $base-size * 2 $base-size * 2;
      min-width: 200px;
    }

    .dropdown__parent > & {
      transform: translateY(-$base-size);
      opacity: 0;
      filter: blur($base-size/3);

      pointer-events: none;
    }
    .dropdown__parent:not(.dropdown__parent--filter):hover > &,
    .dropdown__parent:not(.dropdown__parent--filter):active > &,
    .dropdown__parent:not(.dropdown__parent--filter):focus > & {
      transform: translateY(0);
      opacity: 1;
      filter: blur(0);
      pointer-events: all;
    }
    .dropdown__parent:active > & {
      transform: translateY(0);
      opacity: 1;
      filter: blur(0);
      pointer-events: all;
    }

    & a {
      @include text-xs-regular;
      transition: opacity var(--transition);

      &:hover,
      &:focus {
        opacity: 0.75;
      }
    }

    & ul {
      display: flex;
      flex-direction: column;
      gap: $base-size * 1.5;
      text-align: left;
      max-height: 350px;
      overflow: scroll;
    }
  }

  &__item {
    &-text {
      display: block;
      width: 100%;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }
  }

  &__button {
    align-items: center;
    border-radius: $base-size;
    box-shadow: 0 $base-size * 1.5 $base-size * 3.75 0 rgba(15, 71, 88, 0.08);
    display: flex;
    gap: $base-size;
    overflow: hidden;
    padding: $base-size * 1.5;
    background: $color-white;
    cursor: pointer;

    transition:
      border-color var(--transition),
      transform 0.1s linear;

    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }

    &:after {
      content: '';
      height: $base-size * 2;
      width: $base-size * 2;
      flex: 0 0 auto;

      // position: absolute;
      right: $base-size * 1.5;
      pointer-events: none;
      background-image: $chevron-down-2;
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(0deg);
      transition: transform var(--transition);
    }

    &-text {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      // padding-right: $base-size * 3;

      @include text-xs-regular;
      color: $color-gray-1;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }
  }
}

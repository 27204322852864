.contacts {
  margin-top: $base-size * 4;

  @media (max-width: $max-m) {
    margin-top: 0;
  }

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $base-size * 10;
    margin-bottom: $base-size * 10;

    &-top {
      position: relative;
    }
  }

  &__title {
    margin: $base-size * 2 0 $base-size * 5;
    @include font-h1;

    @media (max-width: $max-l) {
      @include font-h2;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin: $base-size * 4 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
  }

  &__item {
    background: $color-gray-6;
    padding: $base-size * 5;
    border-radius: $base-size * 4;

    display: flex;
    gap: $base-size * 6;

    @media (max-width: $max-l) {
      flex-direction: column;
      padding: $base-size * 3;
    }

    @media (max-width: $max-m) {
      padding: $base-size * 3 $base-size * 2;
      gap: $base-size * 4;
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: $base-size * 6;
      flex: 1 0 calc(50% - #{$base-size * 3});

      @media (max-width: $max-l) {
        flex: 0 0 auto;
      }
      @media (max-width: $max-m) {
        gap: $base-size * 2;
      }
    }

    &-title {
      @include font-h2;
    }

    &-info {
      flex: 1 0 auto;
      @include text-l-medium;

      @media (max-width: $max-l) {
        margin-bottom: $base-size * 3;
      }

      & dl {
        display: flex;
        justify-content: space-between;
        gap: $base-size * 2;
        padding: $base-size * 3 0;
        border-bottom: 1px solid $color-gray-5;

        &:last-child {
          border-bottom: none;

          @media (max-width: $max-l) {
            border-bottom: 1px solid $color-gray-5;
          }
        }

        @media (max-width: $max-m) {
          flex-direction: column;
          gap: $base-size / 2;
          padding: $base-size * 2 0;
        }
      }
      & dt {
        @media (max-width: $max-m) {
          @include text-xs-medium;
        }
      }
      & dd {
        color: $color-green-primary;

        @media (max-width: $max-m) {
          @include text-s-medium;
        }
      }
      & a {
        color: $color-green-primary;

        &:hover,
        &:focus {
          color: $color-green-hover;
        }
      }
    }

    &-button {
      & .button {
        @media (max-width: $max-m) {
          display: flex;
        }
      }
    }

    &-map {
      height: 430px;
      // flex: 1 1 auto;
      flex: 1 0 calc(50% - #{$base-size * 3});
      border-radius: 0 0 $base-size * 3 $base-size * 3;

      @media (max-width: $max-l) {
        margin-top: $base-size * 3;
        flex: 0 0 auto;
      }
    }
  }
}

.text {
  &--mm {
    @include text-m-medium;
  }

  * + p,
  * + ul,
  * + ol,
  * + dl,
  * + pre,
  * + address,
  * + fieldset,
  * + figure,
  * + div {
    margin-top: $content-paragraph-margin;
  }
  & > ul + *,
  & > ol + *,
  & > p + ul,
  & > p + ol {
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    margin-top: $block-margin;
    margin-bottom: $block-margin;
  }

  & ul,
  & ol {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: $base-size;
  }

  & ul {
    list-style-type: none;

    & > li {
      position: relative;
      padding-left: $base-size * 3;
      display: flex;
      flex-direction: column;
      gap: $base-size;

      &:before {
        content: '';
        position: absolute;
        width: $base-size;
        height: $base-size;
        border-radius: $base-size;
        background-color: $color-green-primary;
        left: 0;
        top: $base-size * 1.2;
      }
    }
  }
  & ol {
    counter-reset: index;
    list-style-type: none;

    & > li {
      position: relative;
      padding-left: $base-size * 3;
      display: flex;
      flex-direction: column;
      gap: $base-size;

      &:before {
        counter-increment: index;
        content: counters(index, '.', decimal) ' ';
        position: absolute;
        left: 0;
        color: $color-green-primary;
      }

      & > ol {
        & > li {
          padding-left: $base-size * 4;
        }
      }
    }
  }
}

.side {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 493px;
  width: 100%;
  background: $color-white;
  z-index: 10000;
  box-shadow: 0px 12px 30px 0px #0f475814;
  // display: none;
  // transform: translateX($base-size * 4);
  opacity: 0;
  transform: translateX($base-size * 3);
  filter: blur($base-size);
  visibility: hidden;
  transition: all 0.3s ease 0.1s;
  overflow-y: scroll;

  @media (max-width: $max-m) {
    max-width: 100vw;
  }

  &.active {
    // display: block;
    transition: all 0.2s ease 0s;
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    visibility: visible;
  }

  &__container {
    padding: $base-size * 9 $base-size * 6;
    display: flex;
    flex-direction: column;
    gap: $base-size * 3;

    opacity: 0;
    transform: translateX($base-size * 3);
    transition: all 0.2s ease 0s;

    @media (max-width: $max-m) {
      padding: $base-size * 8 $base-size * 3 $base-size * 5;
    }

    .side.active & {
      transition: all 0.2s ease 0.1s;
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__close {
    display: block;
    width: $base-size * 6;
    height: $base-size * 6;
    position: absolute;
    right: $base-size * 3;
    top: $base-size * 3;

    fill: $color-gray-3;

    &:hover,
    &:focus {
      fill: $color-gray-2;
    }

    @media (max-width: $max-m) {
      right: $base-size * 2;
      top: $base-size * 2;
    }
  }
  &__title {
    @include font-h4;
  }
  &__desc {
    @include text-xs-regular;
    color: $color-gray-1;
  }
}

html.side-active {
  overflow: hidden;
  scrollbar-width: initial; // fix page shift
}

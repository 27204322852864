.partners {
  &-list {
    display: flex;
    gap: $base-size * 6;
    align-items: center;
    justify-content: center;
    // max-width: 100dvw;
    width: 1570px;
    overflow-x: hidden;
    align-self: center;

    @media (max-width: $max-m) {
      overflow-x: scroll;
    }
    &--colored {
      margin-top: 0 !important;
      width: auto;
      flex-wrap: wrap;

      & .partners__item-image {
        opacity: 1;

        @media (max-width: $max-l) {
          height: $base-size * 8;
        }
      }
      & .partners__item-image-bw {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    user-select: none;

    &:hover {
      // filter: grayscale(0);
    }

    &-image {
      opacity: 0;
      transition: opacity var(--transition);

      .partners__item:hover & {
        opacity: 1;
      }
    }

    &-image-bw {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      transition: opacity var(--transition);

      .partners__item:hover & {
        opacity: 0;
      }
    }
  }
}

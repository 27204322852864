.pagination {
  display: flex;
  align-items: center;
  gap: $base-size;

  &__link {
    padding: $base-size;
  }

  &__current {
    padding: $base-size;
    font-weight: $font-weight-bold;
  }
}

.header-dropdown {
  grid: 1 / -1;
  // position: absolute;

  top: 100%;
  width: 100%;
  padding: $base-size * 4 0 $base-size * 8;

  opacity: 0;
  transform: scale(0.98);
  // display: none;
  transition: all 0.1s ease 0s;
  pointer-events: none;
  z-index: 1;

  .header--open & {
    // display: block;
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease 0.1s;
    pointer-events: all;
  }

  &__container {
    @include container-content-grid;
    grid-template-columns: repeat(14, minmax(0, 1fr));
    position: relative;
    z-index: 1;

    & > * {
      grid-column: 1 / -1;
    }
  }
}

.header-menu {
  display: flex;
  color: $color-white;
  grid-column: 3 / -4;
  // padding-left: $base-size * 4;
  gap: $base-size * 6;

  @media (max-width: $max-xl) {
    grid-column: 3 / -1;
  }

  @media (max-width: $max-l) {
    grid-column: 1 / -1;
    // padding-left: 0;
  }

  & a {
    color: $color-white;
  }

  &__left {
    flex: 0 0 auto;
    width: 235px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: $base-size * 3;

    flex: 1 0 auto;
    // width: ;
  }

  &__parent {
    display: flex;
    flex-direction: column;
    gap: $base-size * 3;
    & > a {
      @include font-h4;
    }
  }

  &__sub {
    display: flex;
    flex-direction: column;
    gap: $base-size * 1.5;

    &-item {
      @include text-m-regular;
      opacity: 0.75;
      transition: opacity var(--transition);

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  &__item {
    & > a {
      @include font-h4;
    }
  }
}

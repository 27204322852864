.catalog {
  margin-top: $base-size * 4;

  @media (max-width: $max-m) {
    margin-top: 0;
  }

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }
  &__container {
    @include container-content-grid;

    &:last-child {
      margin-bottom: $base-size * 10;
    }

    & > * {
      grid-column: 1 / -1;
      z-index: 1;
    }

    &--category {
      z-index: 3;
    }
    &--filter {
      padding-bottom: $base-size * 2.5;

      z-index: 2;
      @media (max-width: $max-m) {
        padding-bottom: $base-size * 4;

        // grid-column: 1 / -1;
        // grid-template-columns: subgrid;
      }
    }
    &--products {
      margin-bottom: $base-size * 6.25;

      display: flex;
      flex-direction: column;
      gap: $base-size * 1.5;
    }
    &--description {
      margin-top: $base-size * 6;

      @media (max-width: $max-l) {
        margin-top: $base-size * 4;
      }
    }
  }

  &__bg {
    @include section-bg;
    user-select: none;
    pointer-events: none;
  }
  &__title {
    @include font-h1;

    margin: $base-size * 5 0;

    @media (max-width: $max-l) {
      @include font-h2;
    }

    @media (max-width: $max-m) {
      @include font-h3;
      margin: $base-size * 5 0 $base-size * 3;
    }
  }

  &__category {
    margin-bottom: $base-size * 6;

    @media (max-width: $max-l) {
      margin-bottom: $base-size * 4;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: $base-size * 2.5;
      // padding-bottom: $base-size * 6;

      // @media (max-width: $max-m) {
      //   padding-bottom: $base-size * 4;
      // }
      @media (max-width: $max-m) {
        display: none;
      }
    }

    &-dropdown {
      display: none;

      @media (max-width: $max-m) {
        z-index: 2;
        position: relative;
        display: block;
      }

      &-choices {
        display: flex;

        box-shadow: none;
        padding: $base-size * 2.5 0;
        border: 1px solid $color-gray-5;
        border-left: none;
        border-right: none;
        border-radius: 0;

        & .choices__list--single {
          flex: 1 1 auto;
          overflow: hidden;

          & .choices__item--selectable {
            @include text-m-medium;
            color: $color-primary;
            max-width: 100%;
            text-wrap: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &-item {
      & > a {
        @include text-l-regular;
        color: $color-green-primary;
        cursor: pointer;

        @media (max-width: $max-m) {
          @include text-m-regular;
        }
      }

      &:hover > a,
      &:focus > a {
        color: $color-green-hover;
      }

      &.active {
        & > a {
          color: $color-black;
        }
      }
    }
  }

  &__filter {
    // z-index: 10;

    @media (max-width: $max-m) {
      // overflow-x: scroll; //todo fix overflow-y and disable wrap
      // overflow-y: hidden;
      // padding-left: $base-size * 2;
      // margin-bottom: $base-size * -1.5;
    }

    &-props {
      display: flex;
      flex-wrap: wrap;
      gap: $base-size * 1.5;

      @media (max-width: $max-m) {
        // flex-wrap: nowrap;
      }
    }

    &-prop {
      position: relative;
      flex: 0 0 auto;

      &--checkbox {
        padding: 0 $base-size * 3;

        &:first-child {
          padding-left: 0;
        }
      }

      &-title {
        @include text-xs-regular;
        background: $color-white;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: $base-size * 1.5;
        border-radius: $base-size;
        box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);
        cursor: pointer;
        color: $color-gray-1;
        transition: color var(--easing);

        &:hover,
        &:focus {
          color: $color-black;
        }

        &:after {
          content: '';
          width: $base-size * 2;
          height: $base-size * 2;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7.5L8 11.5L12 7.5' stroke='%233D9D6F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

          transition: transform var(--easing);
        }
      }

      &-select {
        position: absolute;
        top: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;

        .catalog__filter-prop:hover & {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    & .choices__inner {
      background: $color-white;
    }
  }

  &__products {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $base-size * 1.5;

    transition: all 0.3s ease;
    min-height: $base-size * 20;

    @media (max-width: $max-xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: $max-l) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: $max-m) {
      gap: $base-size;
    }

    &.hidden {
      opacity: 0;
    }
    &.arranged {
      opacity: 1;
    }
  }

  &__product {
    display: flex;
    flex-direction: column;
    gap: $base-size * 1.5;
    flex: 1 0 0;
    position: relative;
    min-width: 280px;
    padding: $base-size * 3 $base-size * 1.5 $base-size * 1.5 $base-size * 1.5;

    border-radius: $base-size * 4;
    background: $color-white;
    border-radius: $base-size * 4;
    box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);
    cursor: pointer;
    user-select: none;

    @media (max-width: $max-l) {
      min-width: 0;
    }

    @media (max-width: $max-m) {
      padding: $base-size * 2 $base-size $base-size $base-size;
      border-radius: $base-size * 3.5;

      &:last-child:nth-child(odd) {
        // grid-column: 1 / -1;
        grid-column: span 2;
      }
    }

    &.hide {
      display: none;
    }

    &-link {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;
    }
    &-image {
      height: 285px;

      @media (max-width: $max-m) {
        height: 176px;
      }

      & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: $base-size / 2;
      flex: 1 0 auto;
    }

    &-stock {
      @include text-xs-medium;
      font-size: $base-font-size * 1.4;
      letter-spacing: -0.14px;
      color: $color-gray-2;
      display: flex;
      align-items: center;
      gap: $base-size/2;

      @media (max-width: $max-m) {
        font-size: $base-font-size * 1.1;
      }

      &:before {
        content: '';
        display: block;
        width: $base-size * 0.75;
        height: $base-size * 0.75;
        border-radius: 50%;
        background-color: $color-gray-2;
      }

      &--in-stock {
        color: $color-green-primary;
        &:before {
          background-color: $color-green-primary;
        }
      }
    }

    &-title {
      @include text-s-medium;
      color: $color-black;
      line-height: 1.17;

      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      overflow: hidden;

      @media (max-width: $max-m) {
        font-size: $base-font-size * 1.3;
      }
    }

    & .button {
      color: $color-green-primary;
      width: 100%;
      justify-content: space-between;
    }

    &:hover .button__icon {
      &:before {
        opacity: 1;
      }
    }
  }

  &__description {
    display: flex;
    padding: $base-size * 8 $base-size * 6;
    gap: $base-size * 6;
    justify-content: space-between;
    overflow: hidden;

    background: $color-gray-6;
    border-radius: $base-size * 4;

    @media (max-width: $max-l) {
      gap: 0;
    }
    @media (max-width: $max-m) {
      padding: $base-size * 3 $base-size * 2;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: $base-size * 4;
      max-width: $base-size * 100;
      flex: 1 0 auto;
      // min-width: 500px;

      @media (max-width: $max-xl) {
        max-width: $base-size * 75;
      }

      @media (max-width: $max-l) {
        max-width: $base-size * 62.5;
      }
      @media (max-width: $max-m) {
        flex: 1 1 auto;
        gap: $base-size * 3;
      }
    }
    &-title {
      @include font-h2;

      @media (max-width: $max-m) {
        @include font-h5;
      }
    }

    &-text {
      @include text-m-medium;
      color: $color-gray-1;
    }

    &-image {
      position: relative;
      flex: 1 1 auto;
      justify-content: end;
      align-items: start;
      display: flex;

      @media (max-width: $max-m) {
        display: none;
      }

      & img {
        flex: 0 0 auto;
        width: $base-size * 30;
        max-width: none;

        @media (max-width: $max-l) {
          // position: absolute;
          // left: 0;
          // top: 0;
          // transform: translateX(-50%);
        }
      }
    }
  }

  &__related {
    display: grid;
    gap: $base-size * 4;
    position: relative;
    grid-template-columns: 333px 1fr;
    grid-template-rows: min-content 1fr;

    @media (max-width: $max-xl) {
      flex-direction: column;
      grid-template-columns: 1fr max-content;
    }
    @media (max-width: $max-l) {
      grid-template-columns: 24px 1fr 24px;
      gap: 0;
      grid-template-rows: repeat(3, min-content);
    }
    @media (max-width: $max-m) {
      grid-template-columns: $container-side-margin-catalog 1fr $container-side-margin-catalog;
    }

    & > * {
      z-index: 1;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 1444px;
      transform: translate(-40px, -36px);
      z-index: 0;

      & img {
        max-width: none;
        width: 100%;
        height: auto;
      }
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: $base-size * 4;
      grid-column: 1 / 2;

      @media (max-width: $max-xl) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
    &-title {
      @include font-h2;

      grid-column: 1/2;
      grid-row: 1 / 2;

      hyphens: auto;
      overflow-wrap: break-word;
      display: inline-block;

      @media (max-width: $max-xl) {
        display: flex;
        align-items: center;
      }
      @media (max-width: $max-l) {
        grid-column: 2 / -2;
        grid-row: 1 / 2;
        margin-bottom: $base-size * 4;
      }
      @media (max-width: $max-m) {
        @include font-h3;
      }
    }
    &-link {
      grid-column: 1/2;
      grid-row: 2 / 3;
      flex: 0 0 auto;

      @media (max-width: $max-xl) {
        grid-column: 2/3;
        grid-row: 1/2;
      }
      @media (max-width: $max-l) {
        grid-column: 2 / -2;
        grid-row: 3 / 4;

        & > a.button {
          display: flex;

          & span {
            flex: 1 0 auto;
            text-align: left;
          }
        }
      }
    }
    &-products {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: $base-size * 3;
      grid-column: 2 / -1;
      grid-row: 1 / -1;

      @media (max-width: $max-xl) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
      }
      @media (max-width: $max-l) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        gap: $base-size * 1.5;
        padding: 0 $base-size * 3 $base-size * 4;

        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        & > .catalog__product {
          min-width: 280px;
        }
      }
      @media (max-width: $max-m) {
        padding: 0 $container-side-margin-catalog $base-size * 4;
      }
    }
  }
}

.index-4 {
  display: flex;
  flex-direction: column;
  gap: $base-size * 10;
  position: relative;

  &__title {
    @include font-h2;
    text-align: center;
    margin: 0 auto;
    margin-top: $base-size * 8;
    max-width: $base-size * 100;
    text-wrap: balance;

    @media (max-width: $max-l) {
      margin-top: 0;
    }

    @media (max-width: $max-m) {
      @include font-h4;
      padding: 0 $base-size * 3;
      text-wrap: pretty;
    }
  }

  &__goals {
    display: flex;
    gap: $base-size * 2;
    align-items: start;

    @media (max-width: $max-xl) {
      flex-direction: column;
    }

    &-title {
      flex: 0 0 auto;
      background-color: $color-black;
      padding: $base-size * 1.5 $base-size * 4 $base-size * 1.5 $base-size * 2.5;
      width: 215px;
      border-radius: 22px 22px 0px 22px;
      color: $color-white;
      @include text-s-medium;
    }

    &-list {
      // display: flex;
      // flex: 1 0 auto;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @media (max-width: $max-l) {
      }

      @media (max-width: $max-m) {
        display: flex;
        flex-direction: column;
        gap: 22px;
        padding-bottom: $base-size * 30;

        .index-4:last-child & {
          padding-bottom: 0;
        }
      }
    }

    &-button {
      $circle-size: $base-size * 19;
      $circle-translate: ($button-height-icon - $circle-size)/2;

      position: absolute;
      right: -$circle-translate + $base-size * 7;
      bottom: $base-size * 2.5 - $circle-translate;

      @media (max-width: $max-l) {
        bottom: $base-size * 6;
      }
      @media (max-width: $max-m) {
        right: 50%;
        transform: translateX(50%);
        bottom: auto;
        top: calc(100% - #{$circle-size + $base-size});
      }

      & > .button {
        display: block;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 152px;
          height: 152px;
          border-radius: 50%;
          border: 1px solid rgba($color-gray-5, 0.7);

          transform: translate($circle-translate, $circle-translate);
          transform-origin: center;

          transition: transform 0.4s var(--easing-wave);
        }

        &:hover:after {
          transform: translate($circle-translate, $circle-translate) scale(1.1);
        }
      }
    }
  }

  &__goal {
    padding: $base-size * 4 0 0 $base-size * 4.5;
    position: relative;
    flex: 1 0 auto;
    counter-increment: section;
    height: 380px;
    user-select: none;

    @media (max-width: $max-l) {
      flex: 1 1 auto;
    }
    @media (max-width: $max-m) {
      height: auto;
      padding: $base-size * 4 0 0 $base-size * 3;
    }

    &-title {
      @include text-l-medium;
      max-width: 274px;

      &:before {
        content: counter(section, decimal-leading-zero);
        font-size: $base-size * 8;
        line-height: 1em;
        font-weight: 500;
        color: $color-green-primary;
        display: block;
        margin-bottom: $base-size * 2;

        @media (max-width: $max-m) {
          font-size: $base-size * 5;
        }
      }

      @media (max-width: $max-m) {
        @include text-m-medium;

        display: flex;
        gap: $base-size * 2;
      }
    }

    & > svg,
    & > img {
      display: block;
      position: absolute;
      left: 0;
      top: -8px;
      height: 380px;
      max-width: none;
      z-index: 0;
    }
  }
}

.error {
  margin-bottom: $base-size * 10;
  &__container-top {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
    margin-top: $base-size * 8;
    flex: 1 0 auto;
    max-width: 500px;

    min-height: 460px;

    @media (max-width: $max-l) {
      min-height: 0;
      margin-bottom: 0;
    }
    @media (max-width: $max-m) {
      margin-top: $base-size * 5;
      margin-bottom: $base-size * 3;
    }
  }

  &__wrap {
    display: flex;
    gap: $base-size * 4;
    // align-items: center;
  }

  &__title {
    // margin: $base-size * 2 0 0;
    @include font-h1;

    @media (max-width: $max-l) {
      @include font-h2;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin: 0;
    }
  }

  &__description {
    @include text-l-regular;
    color: $color-gray-1;
    max-width: 500px;

    @media (max-width: $max-m) {
      @include text-m-regular;
    }
  }

  &__bg {
    position: absolute;
    right: 0;
    top: $base-size * 8;
    pointer-events: none;
    z-index: 0;
    width: 884px;

    @media (max-width: $max-l) {
      position: static;
      width: auto;
    }
  }
}

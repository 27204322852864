.content {
  margin-top: $base-size * 4;

  @media (max-width: $max-m) {
    margin-top: $base-size * 5.5;
  }

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
    position: relative;

    grid-column: 2 / 12;
    margin-bottom: $base-size * 10;

    @media (max-width: $max-xl) {
      grid-column: 2 / 17;
    }
    @media (max-width: $max-m) {
      margin-bottom: $base-size * 7.5;
    }
  }

  &__title {
    @include font-h2;

    @media (max-width: $max-l) {
      // margin-top: $base-size * 3;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin-top: 0;
      // margin-bottom: $base-size * 4;
    }
  }

  &__content {
    @include text-s-regular;
    color: $color-gray-1;

    & p,
    & li {
      @include text-s-regular;
      color: $color-gray-1;
    }
  }
}

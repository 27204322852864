.search {
  background: $color-white;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: $base-size * 14;
  z-index: 2;
  box-shadow: 0px 12px 30px 0px #0f475829;
  pointer-events: all;

  opacity: 0;
  visibility: hidden;
  transform: translateY($base-size * -4);
  pointer-events: none;
  filter: blur($base-size * 2);

  transition: all var(--transition);

  @media (max-width: $max-m) {
    height: $base-size * 10;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    transform: translateY(0);
    filter: blur(0);
  }

  &__container {
    // @include container-16;
    display: flex;
    align-items: center;
    gap: $base-size * 3;
    margin: 0 auto;
    max-width: $container-width + ($container-side-margin * 2);

    padding: 0 $base-size * 4;

    height: 100%;
    width: 100%;

    transform: scale(0.99);
    transition: transform 0.3s ease 0.1s;

    @media (max-width: $max-m) {
      padding: 0 $base-size * 2;
    }

    .search.active > & {
      transform: scale(1);
    }

    // z-index: 2;

    &-inner {
      grid-column: 2 / -2;
      display: flex;
      align-items: center;
      gap: $base-size * 3;
    }
  }
  &__input {
    all: unset;

    flex: 1 1 auto;
    height: 100%;
    border: none;
    font-size: $base-size * 2.75;

    &:focus-visible {
      outline: none;
    }

    @media (max-width: $max-m) {
      @include text-xs-regular;
    }
  }
  &__button {
    flex: 0 0 auto;

    @media (max-width: $max-m) {
      display: none;
    }
  }
  &__close {
    flex: 0 0 auto;
    width: $base-size * 6;
    height: 100%;
    display: block;

    & svg {
      fill: $color-gray-3;
    }
  }
}

@mixin container() {
  flex: 0 1 auto;
  margin: 0 auto;
  max-width: $container-width + ($container-side-margin * 2);
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: calc-container-grid(
    $container-side-margin,
    $container-gap
  );

  column-gap: $container-gap;

  @media (max-width: $max-xl) {
    grid-template-columns: calc-container-grid(
      $container-side-margin-l,
      $container-gap
    );
  }

  @media (max-width: $max-l) {
    grid-template-columns: calc-container-grid(
      $container-side-margin-m,
      $container-gap
    );
  }

  @media (max-width: $max-m) {
    column-gap: $container-gap-small;
    grid-template-columns: calc-container-grid(
      $container-side-margin-s,
      $container-gap-small
    );
  }

  & > section:last-child {
    margin-bottom: 0;
  }

  & > * {
    grid-column: 2 / -2;
  }
}

@mixin container-content-grid() {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: $container-gap;

  & > section:last-child {
    margin-bottom: 0;
  }
}
@mixin container-content-grid-16() {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(16, minmax(0, 1fr));
  column-gap: $container-gap;

  & > section:last-child {
    margin-bottom: 0;
  }
}

@mixin container-16() {
  flex: 0 1 auto;
  margin: 0 auto;
  max-width: $container-width + ($container-side-margin * 2);
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: calc-container-grid-16(
    $container-side-margin,
    $container-gap
  );

  column-gap: $container-gap;

  @media (max-width: $max-xl) {
    grid-template-columns: calc-container-grid-16(
      $container-side-margin-l,
      $container-gap
    );
  }

  @media (max-width: $max-l) {
    grid-template-columns: calc-container-grid-16(
      $container-side-margin-m,
      $container-gap
    );
  }

  @media (max-width: $max-m) {
    column-gap: $container-gap-small;
    grid-template-columns: calc-container-grid-16(
      $container-side-margin-s,
      $container-gap-small
    );
  }

  & > section:last-child {
    margin-bottom: 0;
  }

  & > * {
    grid-column: 2 / -2;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: $base-size * 1.5;
  background: rgba($color-gray-5, 0.2);
  color: $color-white;
  padding: $base-size * 1.5 $base-size * 0.75;
  @include text-s-medium;
  user-select: none;
  z-index: 2;

  @media (max-width: $max-xl) {
    width: $base-size * 12.25;
  }

  &__control {
    display: block;
    width: $base-size * 3;
    height: $base-size * 3;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.15s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &__current {
    display: flex;
    gap: 2px;
  }

  & > * {
    flex: 0 0 auto;
  }

  & svg {
    display: block;
  }
}

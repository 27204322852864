.index-catalog {
  display: grid;
  @include container-content-grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  padding: $base-size * 4;
  background: $color-black;
  border-radius: $base-size * 6;
  overflow: hidden;
  color: $color-white;

  @media (max-width: $max-xl) {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
  }
  @media (max-width: $max-l) {
    padding: $base-size * 3;
  }
  @media (max-width: $max-m) {
    margin: 0 (-$base-size * 2);
    padding: $base-size * 1.5;
    width: auto;
    border-radius: $base-size * 4.75;
  }

  & a {
    color: $color-white;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 800px;
    z-index: 0;
    pointer-events: none;
    opacity: 0.8;
    transform: translateX(-50px);
    user-select: none;

    @media (max-width: $max-xl) {
      width: 100%;
      height: 600px;
      transform: translateX(0);
      transform: translateY(-100px);
    }
    @media (max-width: $max-l) {
      transform: translateY(-150px);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        270deg,
        rgba($color-black, 1) 0%,
        rgba($color-black, 0) 38.51%
      );
      // mix-blend-mode: multiply;

      @media (max-width: $max-xl) {
        background: linear-gradient(
          0deg,
          rgba($color-black, 1) 0%,
          rgba($color-black, 0) 38.51%
        );
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__left {
    grid-column: 1 / 5;
    grid-row: 1 / 2;

    display: flex;
    flex-direction: column;
    gap: $base-size * 3;
    padding-right: $base-size * 2;
    z-index: 1;

    @media (max-width: $max-m) {
      padding-right: 0;
    }

    &-title {
      @include font-h1;
      color: $color-white;

      @media (max-width: $max-m) {
        @include font-h3;
      }
    }

    &-panel {
      display: flex;
      flex-direction: column;
      padding: $base-size * 2;
      gap: $base-size * 3;
      flex: 1 0 auto;

      @media (max-width: $max-m) {
        padding-top: $base-size * 2.5;
        gap: $base-size * 2;
      }
    }

    &-desc {
      @include text-l-medium;
      text-wrap: pretty;

      @media (max-width: $max-m) {
        @include text-s-medium;
      }
    }
  }

  &__right {
    grid-column: 5 / -1;
    grid-row: 1 / -1;
    z-index: 1;
  }

  &__button {
    grid-column: 1 / 4;
    grid-row: 2 / -1;
    align-self: end;

    @media (max-width: $max-xl) {
      align-self: stretch;
    }

    & > .button {
      @media (max-width: $max-xl) {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &__sections {
  }

  &__section {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    counter-increment: section;
    padding: $base-size * 4 20px $base-size * 4 86px;
    transition: all 0.3s ease;

    border-radius: $base-size * 3;

    @media (max-width: $max-m) {
      padding-left: 35px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      border-top: 1px solid $color-gray-5;
    }

    &:not(.parent) {
      margin-bottom: -1px;
      align-items: center;
    }
    &:first-of-type {
      &:after {
        display: none;
      }
    }

    // &:hover,
    &.active {
      flex-direction: column;

      @media (max-width: $max-m) {
        padding-left: 35px;
      }

      &:after {
        border-color: transparent;
      }

      & a:hover,
      & a:focus {
        color: rgba($color-white, 0.8);
      }

      & + .index-catalog__section {
        &:after {
          border-top: none;
        }
      }

      & svg {
        &.index-catalog__section-arrow {
          opacity: 0;
          transition: all 0.1s ease;
        }
        &.index-catalog__section-arrow-hover {
          stroke: $color-white;
          pointer-events: none;
          transition: all 0.3s ease 0.2s;

          opacity: 1;
        }
      }

      & .index-catalog__section-bg {
        opacity: 1;
      }
      & .index-catalog__section-subs-wrap {
        opacity: 1;
        // visibility: visible;
      }
      & .index-catalog__section-title {
        @media (max-width: $max-m) {
          @include font-h4;
        }

        &:before {
          color: $color-white;
        }
      }
    }

    & > * {
      z-index: 1;
    }

    &-bg {
      z-index: 0;
      opacity: 0;
      transition: opacity var(--transition);
    }

    &-title {
      display: flex;
      align-items: center;
      // padding: $base-size * 4 $base-size * 7 $base-size * 4 $base-size * 14.375;
      padding: 0;

      @include font-h3;
      color: $color-white;
      flex: 1 0 auto;

      @media (max-width: $max-l) {
        @include font-h3;
      }
      // @media (max-width: $max-l) {
      //   @include font-h4;
      // }

      @media (max-width: $max-m) {
        @include font-h6;
        // @include font-h4;
        // padding: $base-size * 3.75 $base-size * 8 $base-size * 3.75 0;
        display: flex;
        gap: $base-size * 2;

        margin-left: -15px;
      }

      &:before {
        content: counter(section, decimal-leading-zero);
        position: absolute;
        display: block;
        left: 20px;
        color: $color-green-primary;
        // color: $color-white;

        @media (max-width: $max-m) {
          position: static;
        }
      }

      &:hover,
      &:active,
      &:focus {
        color: $color-gray-4;
      }

      & > span {
        flex: 1 0 auto;
      }

      & > svg {
        position: absolute;
        width: $base-size * 6;
        height: $base-size * 6;
        pointer-events: none;
        stroke: $color-green-primary;

        transition: all 0.3s ease 0.2s;

        &.index-catalog__section-arrow {
          right: 0;

          opacity: 1;
        }
        &.index-catalog__section-arrow-hover {
          right: $base-size * 3;
          top: 21px;
          opacity: 0;
          transition: all 0.1s ease;
        }
      }

      & sup {
        font-size: 0.7em;
        margin-left: 0.3em;
      }
    }

    &.parent > &-title {
    }

    &-subs-wrap {
      opacity: 0;
      max-height: 0;
      // visibility: hidden;
      overflow: hidden;
      transition: all 0.3s ease;
    }

    &-subs {
      display: flex;
      flex-wrap: wrap;
      gap: $base-size * 2;
      position: relative;

      // padding-top: 0;
      padding-top: $base-size * 5;

      @media (max-width: $max-m) {
        gap: $base-size * 3;
      }
    }

    &-sub {
      @include text-l-regular;

      @media (max-width: $max-m) {
        @include text-m-regular;
      }

      & > a {
        color: $color-white;

        &:hover,
        &:active,
        &:focus {
          color: $color-gray-4;
        }
      }
    }
  }
}

@custom-media --small-viewport (width >=576px);
@custom-media --medium-small-viewport (width > 768px);
@custom-media --medium-viewport (width >=992px);
@custom-media --large-viewport (width >=1200px);

.goverlay,
.glightbox-mobile .goverlay {
  background: rgba($color-black, 0.3);

  @media (max-width: $max-m) {
    background: $color-white;
  }
}

.glightbox-lab {
  & .gcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $base-size * 3;
  }

  & .gslider {
    width: auto;
    flex: 0 0 auto;
  }

  & .gslide {
    width: auto;

    &-inner-content {
      width: auto;
    }
  }
  & .gslide-media {
    // @media (--medium-small-viewport) {
    //   box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    // }
    box-shadow: none;

    @media (max-width: $max-m) {
      padding: 0 $base-size * 1.5;
    }

    & > img {
      border-radius: $base-size * 3;
      overflow: hidden;
      height: calc(100dvh - #{$base-size * 20});
      min-height: 600px;
      margin: 0;
      max-width: 100%;

      @media (max-width: $max-l) {
        height: calc(100dvh - #{$base-size * 10});

        min-height: 0;
      }

      @media (max-width: $max-m) {
        border-radius: 0;
        height: calc(100dvh - #{$base-size * 7});
        max-width: 90vw;
        object-fit: contain;
      }
    }
  }

  & .gslide-description {
    background: #fff;
  }

  & .gdesc-inner {
    padding: 22px 20px;
  }

  @media (--medium-small-viewport) {
    .description-left,
    .description-right {
      .gdesc-inner {
        position: absolute;
        height: 100%;
        overflow-y: auto;
      }
    }
  }

  & .gslide-title {
    font-size: 1em;
    font-weight: normal;
    color: #000;
    margin-bottom: 19px;
    line-height: 1.4em;
  }

  & .gslide-desc {
    font-size: 0.86em;
    margin-bottom: 0;
    line-height: 1.4em;
  }

  & .gslide-video {
    background: #000;
  }

  & .nav-wrap {
    // display: none;
    opacity: 1;
    transition: all var(--transition);
    pointer-events: none;

    @media (max-width: $max-m) {
      position: absolute;
      bottom: $base-size * 2.5;
      left: 50%;
      transform: translateX(-50%);
      // width: $base-size * 24;
      background: $color-gray-6;
      border-radius: $base-size * 4.5;
      padding: $base-size;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $base-size * 8;

      height: $base-size * 8;
      width: $base-size * 22;

      & .gnext,
      & .gprev {
        position: static;
        transform: none;
      }
    }
  }

  & .gprev,
  & .gnext {
    position: static;

    width: $base-size * 3;
    height: $base-size * 3;
    padding: $base-size * 2;
    background-color: $color-white;
    border-radius: 50%;
    box-sizing: content-box;
    transition: all var(--transition);

    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $max-m) {
      // top: -100%;

      top: auto;
      position: absolute;
      bottom: $base-size * 3;
    }

    & svg {
      stroke: $color-gray-3;
      transition: stroke var(--transition);
    }

    &:hover {
      & svg {
        stroke: $color-black;
      }
    }
  }

  & .gbtn2 {
    // position: static;
  }

  & .gprev {
    left: $base-size * 4;

    @media (max-width: $max-m) {
      left: 50%;
      transform: translateX(calc(-100% - #{$base-size * 3.5}));
    }
  }
  & .gnext {
    right: $base-size * 4;

    @media (max-width: $max-m) {
      right: 50%;
      transform: translateX(calc(100% + #{$base-size * 3.5}));
    }
  }

  & .gclose {
    background: none;
    // width: 35px;
    // height: 35px;
    top: $base-size * 9.5;
    right: $base-size * 3;
    position: absolute;
    width: $base-size * 6;
    height: $base-size * 6;
    transition: opacity var(--transition);

    @media (max-width: $max-m) {
      top: $base-size * 1.5;
      right: $base-size;
    }

    svg {
      // stroke: $color-black;
      width: 100%;
      height: 100%;
      fill: $color-black;

      @media (max-width: $max-m) {
        fill: $color-gray-3;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &.glightbox-closing {
    & .nav-wrap {
      opacity: 0;
    }
  }
}

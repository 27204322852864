$font-family: 'HelveticaNeueCyr', sans-serif;
$base-font-size: 10px;

// COLORS

$color-white: #fff;

$color-black: #1d212e;
$color-red: #f05f71;

$color-gray-1: #585a65;
$color-gray-2: #6e707a;
$color-gray-3: #90929a;
$color-gray-4: #c2c3c8;
$color-gray-5: #cdcfd2b2;
$color-gray-6: #f9f9f9;
$color-gray-7: #586067;

$color-green-primary: #3d9d6f;
$color-green-hover: #1f8b57;

$color-primary: #e36928;

:root {
  --box-shadow: 0 10px 40px #{$color-gray-4};
  --transition: 0.2s ease; // cubic-bezier(0.28, 0.34, 0.42, 0.98)
  --easing: cubic-bezier(0.51, 0.09, 0.53, 0.9);
  --easing-slide: cubic-bezier(0.19, 0, 0.43, 1);
  --easing-wave: cubic-bezier(0.34, 0.59, 0.31, 1.06);
  --easing-long: cubic-bezier(0, 0.64, 0.22, 1);
  --easing-bg: cubic-bezier(0, 0, 0, 0.96);

  // {"ease":".25,.1,.25,1","linear":"0,0,1,1","ease-in":".42,0,1,1","ease-out":"0,0,.58,1","ease-in-out":".42,0,.58,1"}
}

// MEDIA SIZES AND BREAKPOINTS

$breakpoint-small: 390px !default;
$breakpoint-medium: 744px !default;
$breakpoint-large: 1024px !default;
$breakpoint-xlarge: 1512px !default;

$max-s: $breakpoint-small - 1;
$max-m: $breakpoint-medium - 1;
$max-l: $breakpoint-large - 1;
$max-xl: $breakpoint-xlarge - 1;

$desktop-width: 1520px;
$tablet-width: 768px; // used for tablet columns
$mobile-min-width: 375px;
$mobile-min-height: 667px;

$fhd-width: 1920px;
$fhd-height: 992px;

// CONTAINERS
$base-size: 8px;
$border-radius: $base-size;

// 1520px, 12 columns + 2 column widths + 11 gutters = 95 pieces. 1 piece = 16px
$container-width: 1368px;
$container-width-small: 600px;
$container-side-margin: $base-size * 9;
$container-side-margin-l: $base-size * 4;
$container-side-margin-m: $base-size * 3;
$container-side-margin-s: $base-size * 1.5;
$container-side-margin-catalog: $base-size * 1.5;

$container-gap: $base-size * 2;
$container-gap-small: $base-size;

$container-width-effective: $container-width - $container-gap * 2;

// COLUMNS

// 96px - 1 column
// 16px - column gutter

$column-width-px: 98px;
$column-width: percent-view(96px, $desktop-width, 'w'); // 12 columns
$column-width-medium: percent-view(112px, $tablet-width); // 6 columns
$column-width-small: percent-view(106px, $mobile-min-width, 'w'); // 3 columns

$column-margin-px-small: 8px;
$column-margin-px: 16px;

$column-margin: $base-size * 2;

// BLOCKS
// CONTAINERS > BLOCKS
$block-padding: responsive-value(12px, 24px);
$block-margin: responsive-value(24px, 48px); // top bottom margin between blocks

$block-margin-l: $base-size * 6;
$block-margin-m: $base-size * 6;
$block-margin-s: $base-size * 4;

// MARGINS

$content-paragraph-margin: $base-size * 3;

// BUTTONS
$button-height-l: $base-size * 8;
$button-height-icon: $base-size * 7;
$button-height-m: $base-size * 5.5;

$button-drop-shadow: 0 $base-size * 1.5 $base-size * 3.75 0 rgba(15, 71, 88, 0.08);

// animations
$animation-translate: 50px;
$easing: cubic-bezier(0.77, 0, 0.175, 1);

// export vars to js
:export {
  colorGray: $color-gray-4;
}

// fonts
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-semibold: 600;

// Gradient bg
$header-bg-1-translate: translate(35%, -70%);
$header-bg-2-translate: translate(-40%, -70%);
$header-bg-3-translate: translate(25%, -35%);

// --

$chevron-down: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 15.6 8.5' style='enable-background:new 0 0 15.6 8.5;' xml:space='preserve'%3E%3Cpolygon points='15.6,0.7 14.8,0 7.8,7.1 0.7,0 0,0.7 7.1,7.8 7.1,7.8 7.8,8.5 7.8,8.5 7.8,8.5 8.5,7.8 8.5,7.8 '/%3E%3C/svg%3E%0A");

$chevron-down-2: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7.5L8 11.5L12 7.5' stroke='%233D9D6F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

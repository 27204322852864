$header-height: $base-size * 7;
$menu-border-radius: $base-size * 4;

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  height: $header-height;

  background-color: $color-black;

  &__inner {
    position: relative;
    width: 100%;
    pointer-events: none;
  }

  &__container {
    @include container-16;
    // position: relative;
    // position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;

    &-inner {
      // position: relative;
      height: $header-height;
      display: flex;
      width: 100%;
      align-items: center;
      // grid-template-columns: subgrid;
      // justify-content: space-between;
      pointer-events: all;
      gap: $base-size * 9;
      grid-column: 1 / -1;
      padding: 0 $base-size * 4;

      @media (max-width: $max-xl) {
        grid-column: 2 / -2;
        padding: 0;
      }

      @media (max-width: $max-l) {
      }

      @media (max-width: $max-m) {
        display: flex;
        justify-content: space-between;
        gap: $base-size * 2;
      }
    }
  }

  &__gradient-bg {
    grid-column: 1 / -1;
    opacity: 0;
    z-index: 0;
    transform: translateY(-$base-size * 4);
    filter: blur($base-size * 1.5);
    transition: all 0.2s ease;
    transition-delay: 0s;

    border-radius: 0 0 $menu-border-radius $menu-border-radius;

    body:not(.header--open) & {
      & .gradient-bg__1 {
        transform: translate(75%, -120%);
      }
      & .gradient-bg__2 {
        transform: translate(-90%, -130%);
      }
      & .gradient-bg__3 {
        transform: translate(45%, -95%);
      }
    }

    body.header--open & {
      transform: translateY(0);
      opacity: 1;
      filter: blur(0);
      transition-delay: 0s;

      & .gradient-bg__1 {
        transform: $header-bg-1-translate;
      }
      & .gradient-bg__2 {
        transform: $header-bg-2-translate;
      }
      & .gradient-bg__3 {
        transform: $header-bg-3-translate;
      }
    }
  }

  &__logo {
    // grid-column: 1 / 3;
    align-self: center;
    flex: 0 0 auto;
    width: $base-size * 19.75;

    @media (min-width: $breakpoint-xlarge) {
      // position: absolute;
      // left: $base-size * 4;
    }

    @media (max-width: $max-m) {
      flex: 1 0 auto;
    }
  }
  &__burger {
    // padding-left: $base-size * 4;
    grid-column: 3 / 6;
  }
  &__right {
    // grid-column: 6 / -1;
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $base-size * 3;
    transition: all var(--transition);

    @media (max-width: $max-l) {
      display: none;
    }

    @media (max-width: $max-m) {
      display: none;
    }

    .header--open & {
      opacity: 0;
      visibility: hidden;
    }

    &-item {
      color: $color-white;
      @include text-xs-regular;
      position: relative;
      cursor: default;

      & > a {
        display: flex;
        gap: $base-size / 2;
        color: $color-white;
        padding: $base-size + 3px 0;
        align-items: center;
        transition: opacity var(--transition);

        &:hover,
        &:focus {
          opacity: 0.75;
        }
      }

      &.parent {
        & a:after {
          content: '';
          width: $base-size * 2;
          height: $base-size * 2;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7L8 11L12 7' stroke='%236E707A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &-sub {
      background: $color-white;
      border-radius: $base-size * 2;
      padding: $base-size * 2 $base-size * 2.5;
      text-wrap: nowrap;

      position: absolute;
      top: calc(100% + 0px);
      left: -$base-size * 2.5;
      z-index: 2;

      display: flex;
      flex-direction: column;
      gap: $base-size;

      box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);

      transition: all var(--transition);

      .header__right-item > & {
        transform: translateY(-$base-size);
        opacity: 0;
        filter: blur($base-size/3);

        pointer-events: none;
      }
      .header__right-item:hover > & {
        transform: translateY(0);
        opacity: 1;
        filter: blur(0);
        pointer-events: all;
      }

      & > li {
        & > a {
          color: $color-gray-2;
          transition: color var(--transition);
          text-wrap: nowrap;
        }
        &.active > a,
        &:active > a {
          color: $color-black;
        }
        & > a:hover,
        & > a:focus {
          color: $color-gray-1;
        }
      }
    }
  }

  &__search {
    margin-left: $base-size * -7;

    @media (max-width: $max-m) {
      margin-left: 0;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $base-size * 6;
      height: $base-size * 6;
      // display: block;

      @media (max-width: $max-m) {
        width: $base-size * 6;
        height: $base-size * 6;
      }

      & svg {
        @media (max-width: $max-m) {
        }
      }
    }

    &-icon {
      width: $base-size * 3;
      height: $base-size * 3;

      @media (max-width: $max-m) {
        display: none;
      }

      &-mobile {
        display: none;

        @media (max-width: $max-m) {
          display: block;
          width: $base-size * 6;
          height: $base-size * 6;
        }
      }
    }
  }
}

.feedback {
  background: $color-white;
  border-radius: $base-size * 6;
  position: relative;
  overflow: hidden;

  // padding: $base-size * 5;

  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: $column-margin;

  @media (max-width: $max-m) {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
    border-radius: $base-size * 4.75;
  }

  &__title {
    @include font-h2;

    @media (max-width: $max-m) {
      @include font-h3;
    }
  }

  &__desc,
  &__desc p {
    @include text-m-medium;
    color: $color-gray-1;

    @media (max-width: $max-m) {
      @include text-s-medium;
    }
  }

  &__text {
    grid-column: 1 / 5;
    padding: $base-size * 5 0 $base-size * 5 $base-size * 5;
    display: flex;
    flex-direction: column;
    gap: $base-size * 3;
    z-index: 1;

    @media (max-width: $max-xl) {
      grid-column: 1 / 7;
      padding-right: $base-size * 3;
    }

    @media (max-width: $max-l) {
      grid-column: 1 / -1;
      padding: $base-size * 5 $base-size * 3 $base-size $base-size * 3;
    }

    @media (max-width: $max-m) {
      padding: $base-size * 4 $base-size * 1.5 $base-size * 0 $base-size * 2.5;
      gap: $base-size * 2;
    }
  }

  &__circles {
    align-content: end;
    flex: 1 0 auto;
    user-select: none;

    @media (max-width: $max-l) {
      display: none;
    }
  }

  &__blurred-circle {
    width: 443px;
    height: 443px;
    filter: blur(10px);
    position: absolute;
    bottom: 60px;
    transform: translateX(-30%);
    z-index: 0;
    user-select: none;
  }

  &__form {
    grid-column: 5 / -1;
    padding: $base-size * 5 $base-size * 5 $base-size * 5 $base-size * 3;
    position: relative;

    @media (max-width: $max-xl) {
      grid-column: 7 / -1;
      padding-left: 0;
    }

    @media (max-width: $max-l) {
      grid-column: 1 / -1;
      padding: $base-size $base-size * 3 $base-size * 3 $base-size * 3;
    }
    @media (max-width: $max-m) {
      padding: $base-size $base-size * 1.5 $base-size * 1.5 $base-size * 1.5;
    }

    &-inner {
      transition: all var(--transition);
      opacity: 1;
      pointer-events: all;

      &.animated {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &__message {
    background: #f9f9f9;
    border-radius: $base-size * 2;
    padding: $base-size * 2 $base-size * 3;
    text-align: center;
    box-shadow: 0px 12px 30px 0px rgba(15, 71, 88, 0.08);

    &-wrap {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      z-index: 1;

      opacity: 0;
      visibility: hidden;
      transition: all var(--transition);
      pointer-events: none;

      &.visible {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

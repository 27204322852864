body {
  font-family: $font-family;
  @include text-s-regular;
  font-weight: 400;
  color: $color-black;

  @media (max-width: $max-m) {
    @include text-xs-regular;
  }
}

a {
  color: $color-black;
  text-decoration: none;
  // text-underline-offset: 0.3em;
  // text-decoration-color: rgba($color-green, 0.4);
  transition: all var(--transition);

  & > svg {
    transition: all var(--transition);
  }

  &:hover,
  &:active {
    text-decoration: none;
    // text-decoration-color: rgba($color-green, 0);
    // text-underline-offset: 0.7em;
    color: $color-gray-4;

    & > svg {
      opacity: 0.7;
    }
  }
  &:focus {
    // text-decoration: underline solid rgba(0,0,0,0.1);
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-black;
}

h1,
.h1 {
  @include font-h1;

  &--margin {
    margin-top: $base-size * 8;
    margin-bottom: $base-size * 4;
  }
  & a {
    text-decoration: none;
  }

  // &:last-child {
  //   margin-bottom: 0;
  // }
}

h2,
.h2 {
  @include font-h2;
  // margin-top: $base-size * 6;
  // margin-bottom: $base-size * 3;

  &--margin {
    margin-top: $base-size * 6;
    margin-bottom: $base-size * 3;
  }
}

h3,
.h3 {
  @include font-h3;
  // margin-top: $base-size * 4;
  // margin-bottom: $base-size * 2;

  &--margin {
    margin-top: $base-size * 4;
    margin-bottom: $base-size * 2;
  }
}

h4,
.uk-h4,
.h4 {
  @include font-h4;
}

input,
option,
select {
  font-family: $font-family;
}

p {
  @include text-s-regular;

  @media (max-width: $max-m) {
    @include text-xs-regular;
  }
}

p + h3,
p + h4 {
  margin-top: $base-size * 4;
}

h3 + h3,
h4 + h4 {
  margin-top: $base-size * 4;
}

p + p {
  margin-top: $content-paragraph-margin;
}

.bold {
  font-weight: bold;
}

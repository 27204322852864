.breadcrumbs {
  margin: 0 0 $base-size * 3;
  display: flex;
  flex-wrap: wrap;
  gap: $base-size/2;

  &__item {
    display: flex;
    gap: $base-size/2;

    @include text-xs-regular;
    color: $color-gray-3;

    &:not(:last-child):after {
      content: '—';
    }

    & > a {
      @include text-xs-regular;
      color: $color-gray-3;

      &:hover,
      &:focus {
        color: $color-gray-2;
      }
    }
  }
}

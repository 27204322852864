.company {
  margin-top: $base-size * 4;

  @media (max-width: $max-m) {
    margin-top: 0;
  }

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $base-size * 10;
    margin-bottom: $base-size * 10;

    &-top {
      position: relative;
    }
  }
  &__bg {
    position: absolute;
    height: 606px;
    top: -$base-size * 2;
    left: -$base-size * 6;
    z-index: 0;
    pointer-events: none;

    & img {
      max-width: none;
      height: 100%;
      width: auto;
      object-fit: contain;
      object-position: left top;
      opacity: 1;
    }
  }
  &__menu {
    display: flex;
    flex-wrap: wrap;
    gap: $base-size * 2.5;
    margin-bottom: $base-size * 6;

    @media (max-width: $max-m) {
      display: none;
    }

    &-item {
      & > a {
        @include text-l-regular;
        color: $color-green-primary;
        cursor: pointer;

        @media (max-width: $max-m) {
          @include text-m-regular;
        }
      }

      &:hover > a,
      &:focus > a {
        color: $color-green-hover;
      }

      &.active {
        & > a {
          color: $color-black;
        }
      }
    }
  }

  &__title {
    margin: $base-size * 2 0 $base-size * 5;
    @include font-h1;

    @media (max-width: $max-l) {
      @include font-h2;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin: $base-size * 4 0;
    }
  }

  &__description {
    display: flex;
    padding: $base-size * 8 $base-size * 6;
    gap: $base-size * 6;
    justify-content: space-between;
    overflow: hidden;
    z-index: 1;

    border: 1px solid #44d7b3;
    // border-image-source: radial-gradient(
    //     45.81% 45.81% at 50% 44.58%,
    //     #ffffff 0%,
    //     #44d7b3 88.97%
    //   )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    // border-image-slice: 1;

    border-radius: $base-size * 4;

    @media (max-width: $max-l) {
      gap: 0;
      padding: $base-size * 6 $base-size * 4;
    }
    @media (max-width: $max-m) {
      padding: $base-size * 3 $base-size * 2;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: $base-size * 4;
      max-width: $base-size * 100;
      flex: 1 0 auto;

      @media (max-width: $max-xl) {
        max-width: $base-size * 75;
      }

      @media (max-width: $max-l) {
        max-width: $base-size * 62.5;
      }
      @media (max-width: $max-m) {
        flex: 1 1 auto;
        gap: $base-size * 3;
      }
    }
    &-title {
      @include font-h2;

      @media (max-width: $max-l) {
        @include font-h3;
      }
      @media (max-width: $max-m) {
        @include font-h5;
      }
    }

    &-text {
      @include text-m-medium;
      color: $color-gray-1;

      @media (max-width: $max-m) {
        @include text-xs-medium;
      }

      & p,
      & li {
        @include text-m-medium;

        @media (max-width: $max-m) {
          @include text-xs-medium;
        }
      }
    }

    &-image {
      position: relative;
      flex: 1 1 auto;
      justify-content: end;
      align-items: start;
      display: flex;

      @media (max-width: $max-m) {
        display: none;
      }

      & img {
        flex: 0 0 auto;
        width: $base-size * 32;
        max-width: none;

        @media (max-width: $max-l) {
          // position: absolute;
          // left: 0;
          // top: 0;
          // transform: translateX(-50%);
        }
      }
    }
  }

  &__panel {
    display: grid;
    gap: $base-size * 15.75;
    grid-template-columns: auto 333px;
    grid-template-rows: auto minmax(0, 1fr);
    padding: $base-size * 8 $base-size * 8 0 $base-size * 6;
    background: $color-gray-6;
    border-radius: $base-size * 4;

    @media (max-width: $max-l) {
      grid-template-columns: auto 266px;
      padding: $base-size * 6 $base-size * 4 0;
    }

    @media (max-width: $max-m) {
      padding: $base-size * 3 $base-size * 2 0;
      display: flex;
      flex-direction: column;
    }

    &-title {
      @include font-h3;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding-right: $base-size * 4;
      text-wrap: balance;

      @media (max-width: $max-l) {
        grid-column: 1 / -1;
      }
      @media (max-width: $max-m) {
        @include font-h4;
      }
    }
    &-link {
      flex: 0 0 auto;
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      align-self: end;
      padding-bottom: $base-size * 6;

      @media (max-width: $max-l) {
        padding-bottom: $base-size * 4;
      }

      @media (max-width: $max-m) {
        align-self: start;
        padding-bottom: $base-size * 2;
      }
    }
    &-image {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      mix-blend-mode: multiply;

      align-content: end;
      justify-self: center;
      max-width: 333px;

      @media (max-width: $max-l) {
        grid-row: 2 / 3;
      }
    }
  }
}

.index-2 {
  padding-top: 103px;
  padding-bottom: 84px;

  @media (max-width: $max-l) {
    padding-bottom: 51px;
  }

  @media (max-width: $max-m) {
    padding-top: $base-size * 6;
    padding-bottom: 0;
  }

  &__grid {
    @include container-content-grid;
    position: relative;
    user-select: none;

    @media (max-width: $max-l) {
      display: flex;
      flex-direction: column;
    }

    &-bg {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      transform: translateY(-50%);

      @media (max-width: $max-xl) {
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 1368px;
        width: 100vw;
      }

      @media (max-width: 1160px) {
        left: 40%;
      }

      @media (max-width: $max-m) {
        display: none;
      }
    }

    &-bg-mobile {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      display: none;
      min-width: 780px;

      @media (max-width: $max-m) {
        display: block;
      }
    }
  }

  &__panels {
    grid-column: 2 / -2;
    display: grid;
    grid-template-columns: subgrid;

    @media (max-width: $max-xl) {
      grid-column: 1 / -1;
    }
    @media (max-width: $max-l) {
      display: flex;
      flex-direction: column;
      gap: $base-size * 4;
    }

    &-bg {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 396px;
      width: auto;
      grid-column: 5 / -1;
      transform: translateY(25%);
      user-select: none;

      @media (max-width: $max-l) {
        height: $base-size * 59.75;
        width: 100%;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media (max-width: $max-l) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          max-width: none;
          width: auto;
        }
      }
    }

    &-right {
      grid-column: 5 / -1;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $base-size * 12;
      margin-left: -$base-size * 5;

      @media (max-width: $max-xl) {
        grid-column: 6 / -1;
        gap: $base-size * 4;
      }
      @media (max-width: $max-l) {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      // & > .panel {
      //   grid-column: span 1;
      // }
    }
  }

  &__card {
    position: relative;
    padding: $base-size * 3 $base-size * 3 $base-size * 4;
    border-radius: $base-size * 4;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    grid-column: 1 / 4;

    @media (max-width: $max-xl) {
      grid-column: 1 / 5;
      margin-right: -$base-size * 3;
    }
    @media (max-width: $max-l) {
      margin-right: 0;
    }

    &-title {
      @include font-h3;

      @media (max-width: $max-m) {
        @include font-h4;

        padding-right: $base-size * 6;
      }
    }
    &-text {
      @include text-l-medium;
      text-wrap: balance;

      @media (max-width: $max-m) {
        @include text-s-medium;
      }
    }

    &-drop {
      display: none;

      @media (max-width: $max-l) {
        display: block;
        position: absolute;
        right: $base-size * 3;
        top: $base-size * 3;
        width: $base-size * 13.5;
        height: $base-size * 13.5;
      }

      @media (max-width: $max-m) {
        width: $base-size * 6;
        height: $base-size * 6;
      }

      & > .big {
        @media (max-width: $max-m) {
          display: none;
        }
      }
      & > .small {
        display: none;

        @media (max-width: $max-m) {
          display: block;
        }
      }
    }
    &-content {
      position: relative;
      z-index: 2;
      color: $color-white;

      display: flex;
      flex-direction: column;
      gap: $base-size * 9;

      & > * {
        color: $color-white;
      }

      @media (max-width: $max-l) {
        width: 50%;
        gap: $base-size * 3.75;
      }

      @media (max-width: $max-m) {
        width: 100%;
        gap: $base-size * 3;
      }
    }
  }

  &__feature {
    position: relative;
    padding: $base-size * 3 $base-size * 3 $base-size * 4;
    border-radius: $base-size * 4;
    overflow: hidden;
    // grid-column: span 3;
    display: flex;
    flex-direction: column;
    padding-right: 0;

    justify-content: end;

    &-title {
      @include font-h1;
      font-size: $base-size * 8;
      color: $color-green-primary;

      & > sup {
        font-size: 0.55em;
        margin-left: $base-size * 0.5;
      }

      @media (max-width: $max-l) {
        width: $base-size * 23.25;
        flex: 0 0 auto;
      }
      @media (max-width: $max-m) {
        width: $base-size * 12.75;
        font-size: $base-size * 5;
        line-height: 1;
      }
    }

    &-content {
      position: relative;
      z-index: 2;
      color: $color-white;

      display: flex;
      flex-direction: column;

      gap: $base-size * 1.5;
      justify-content: end;

      @media (max-width: $max-l) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $base-size * 1.5;
        justify-content: space-between;
      }
      @media (max-width: $max-m) {
        align-items: start;
      }
    }
    &-text {
      @include text-l-medium;
      text-wrap: balance;
      color: $color-black;
      height: $base-size * 10.75;
      flex: 1 1 auto;

      @media (max-width: $max-l) {
        height: auto;
      }

      @media (max-width: $max-m) {
        @include text-s-medium;
      }
    }
  }
}

.license {
  margin-top: $base-size * 4;

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  &__bg {
    @include section-bg;
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    gap: $base-size * 5;
    position: relative;
  }
  &__container {
    margin-bottom: $base-size * 10;
    z-index: 1;
  }
  &__title {
    @include font-h1;
    margin-top: $base-size * 2;

    @media (max-width: $max-l) {
      margin-top: $base-size * 3;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin-top: 0;
      margin-bottom: $base-size * 4;
    }
  }

  &__list {
    // display: grid;
    // grid-template-columns: repeat(4, minmax(0, 1fr));
    // display: flex;
    flex-wrap: wrap;
    gap: $base-size * 2;

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @media (max-width: $max-xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: $max-l) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $base-size * 2;
    }
    @media (max-width: $max-m) {
      gap: $base-size;
    }
  }
  &__item {
    // flex: 0 0 calc(25% - #{$base-size * 2});
    // max-width: calc(50% - #{$base-size});
    padding: $base-size * 1.5;
    padding-top: $base-size * 3;
    background: $color-gray-6;
    border-radius: $base-size * 3;
    user-select: none;

    @media (max-width: $max-xl) {
      // flex-basis: calc(33.3% - #{$base-size * 2});
    }
    @media (max-width: $max-l) {
      // flex-basis: calc(50% - #{$base-size});
    }
    @media (max-width: $max-m) {
      padding: $base-size;
      padding-top: $base-size * 1.5;
      border-radius: $base-size * 2;
    }

    & img {
      width: 100%;
    }
  }
}

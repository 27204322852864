.index-slider {
  display: grid;

  grid-template-columns: subgrid;
  grid-column: 1 / 6;
  grid-row: 2 / 3;
  margin-bottom: 73px;

  @media (max-width: $max-xl) {
    grid-column: 1 / -1;
    display: flex;
    gap: $base-size * 4;
    margin-bottom: 79px;

    min-height: 0;
    min-width: 0; // Better flex item sizing
  }

  @media (max-width: $max-l) {
    flex-direction: column;
    margin-bottom: 86px;
  }

  @media (max-width: $max-m) {
    margin-bottom: 58px;
  }

  &__wrapper {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    overflow: hidden;
    width: 100%;

    @media (max-width: $max-xl) {
      grid-column: 3 / -1;
      overflow: visible;

      flex: 1;
      min-width: 0; // Prevents overflow
      width: auto; // Let flex handle the width
    }
  }

  &__nav {
    &-wrap {
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      position: relative;
      z-index: 2;

      @media (max-width: $max-xl) {
        order: -1;
        flex-shrink: 0;
      }

      @media (max-width: $max-l) {
        order: 1;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $base-size * 5.5;
    flex: 0 0 auto;
    max-width: 100%;
    transition:
      transform,
      opacity 0.2s ease 0.15s !important;

    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 0;
    }

    &.swiper-slide-prev {
      transition:
        transform 0.4s linear 0.5s,
        opacity 0.1s ease 0s !important;
    }

    @media (max-width: $max-xl) {
      width: 446px;

      &.swiper-slide-prev {
        opacity: 0;
      }
      &.swiper-slide-next {
        opacity: 1;
      }
    }

    @media (max-width: $max-m) {
      width: 320px;
    }

    &-top {
      position: relative;
    }

    &-image {
      border-radius: $base-size * 2;
      overflow: hidden;
      user-select: none;
    }

    &-desc {
      color: $color-white;
      @include text-s-regular;

      @media (max-width: $max-m) {
        @include text-xs-regular;
      }
    }

    &-button {
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      right: 57px;

      @media (max-width: $max-m) {
        right: 25px;
      }
    }
  }
}

.index-5-slider {
  display: grid;

  grid-template-columns: subgrid;
  grid-column: 1 / 6;
  grid-row: 2 / 3;

  margin-bottom: 73px;

  @media (max-width: $max-xl) {
    grid-column: 4/-1;
    position: relative;
  }

  @media (max-width: $max-l) {
    grid-column: 3 / -1;
    margin-bottom: 62px;
  }

  @media (max-width: $max-m) {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: $base-size * 3.5;
  }

  &__wrapper {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    overflow: hidden;

    @media (max-width: $max-xl) {
      grid-column: 1 / -1;
      overflow: visible;
    }
  }

  &__nav {
    color: $color-black;
    background-color: rgba($color-white, 0.8);

    &-wrap {
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      z-index: 2;

      @media (max-width: $max-xl) {
        position: absolute;
        right: calc(100% + #{$base-size * 2});
      }
      @media (max-width: $max-m) {
        position: static;
      }
    }
  }
}

.index-5-slide {
  display: flex;
  flex-direction: column;
  gap: $base-size * 2;
  flex: 0 0 auto;
  max-width: 100%;
  transition:
    transform 0.2s ease 0s,
    opacity 0.2s ease 0.3s;

  &.swiper-slide-prev,
  &.swiper-slide-next {
    opacity: 0;
  }

  &.swiper-slide-prev {
    transition:
      transform 0s linear 0.5s,
      opacity 0.2s ease 0s,
      filter 0.5s linear 0s;
  }

  @media (max-width: $max-xl) {
    width: 446px;

    &.swiper-slide-prev {
      opacity: 0;
      filter: blur(10px);
    }
    &.swiper-slide-next {
      opacity: 1;
    }
  }

  @media (max-width: $max-m) {
    width: 320px;
  }

  &__top {
    position: relative;
  }

  &__image {
    border-radius: $base-size * 2;
    overflow: hidden;
    user-select: none;
  }

  &__title {
    margin-top: $button-height-icon / 2;
    @include font-h4;

    @media (max-width: $max-m) {
      @include font-h6;
      font-weight: 700;
      color: $color-gray-1;
    }
  }

  &__desc {
    @include text-s-regular;

    @media (max-width: $max-m) {
      @include text-xs-regular;
    }
  }

  &__button {
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    right: 57px;

    @media (max-width: $max-m) {
      right: 33px;
    }
  }
}

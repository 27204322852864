$input-height: $base-size * 6;
$input-padding: $base-size * 2;
$line-height-label: 1.3;
$icon-size: $base-size * 3;

.form {
  display: flex;
  flex-direction: column;
  gap: $base-size * 2.5;

  &__wrap {
    max-height: 1500px;
    opacity: 1;
    overflow: hidden;
    transition: all var(--transition);
  }

  &__row {
    display: flex;
    flex-direction: column;
    // gap: $base-size;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &--checkbox {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__input,
  &__textarea,
  &__select {
    @include text-s-regular;
    font-family: $font-family;

    // height: $input-height;
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-gray-4;
    background: transparent;

    color: $color-gray-1;
    box-sizing: border-box;
    box-shadow: none;
    appearance: none;
    outline: none;

    padding: 0;
    height: $input-height;
    resize: none;

    transition: var(--transition);
    transition-property: all;
    transition-property: color, background-color, border;

    &:hover {
      outline: none;
      border-color: $color-gray-4;
    }
    &:focus {
      border-color: $color-gray-1;
    }
    &:required {
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      background-color: $color-gray-6;
      color: $color-gray-4;
    }
    &::placeholder {
      color: $color-gray-3;
      opacity: 1;
    }

    &.success {
      border-color: $color-green-primary;
    }
    &.invalid,
    &.just-validate-error-field {
      border-color: $color-red;
    }
  }

  input[type='checkbox'] {
    width: $base-size * 2;
    height: $base-size * 2;

    appearance: none;

    &:before {
      content: '';
      display: block;
      width: $base-size * 2;
      height: $base-size * 2;

      background: $color-gray-2;
      border-radius: $base-size / 2;
    }
    &:checked {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.82367 6.47487L4.82373 6.47494L3.76307 7.5356L0.227539 4.00007L1.2882 2.93941L3.76301 5.41422L8.71274 0.464478L9.7734 1.52514L4.82367 6.47487Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &__label {
    display: block;
    top: 0; //$input-height / 6.667;
    position: absolute;
    left: 0;
    // top: percent-view(0px);
    pointer-events: none;
    transform: translateY(-$base-size);
    user-select: none;

    @include text-xs-regular;
    font-size: $base-font-size * 1.3;
    transition: all 0.1s;
    color: $color-gray-3;
  }

  & .form__input:placeholder-shown ~ .form__label,
  & .form__textarea:placeholder-shown ~ .form__label {
    opacity: 1;
    visibility: visible;
    // top: $input-height / 2;
    transform: translateY($base-size * 1.5);
    @include text-s-regular;
  }
  & .form__textarea:placeholder-shown ~ .form__label {
    //top: $input-height / 2;
    transform: translateY($base-size * 1.5);
  }
  & .form__input:focus ~ .form__label,
  & .form__textarea:focus ~ .form__label {
    // top: percent-view($input-height / 1.5);
    // top: 0;
    // transform: translateY(50%);
  }

  &__textarea {
    height: auto;
    min-height: $base-size * 3;
    padding-top: $base-size * 2;
    overflow-y: hidden;
    box-sizing: border-box;
    resize: none;
  }

  &__note,
  .validation-error {
    @include text-s-regular;

    // margin-bottom: -0.5rem;
    bottom: 0;
    left: $input-padding;
    color: $color-red;
  }

  &__button {
  }

  &__submit {
    // align-items: center;
    // margin-top: $base-size * 2;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    gap: $base-size * 2.5;

    &-button {
      appearance: none;
      font-family: $font-family;

      @include button();

      flex: 0 0 auto;
      align-self: flex-start;

      @media (max-width: $max-m) {
        width: 100%;
      }
    }

    &-note {
      @include text-xs-regular;
      text-wrap: balance;

      color: $color-gray-3;
      // text-align: center;

      @media (max-width: $max-l) {
      }
    }
  }

  .validation-error + &__note {
    display: none;
  }

  & a {
    color: $color-green-primary;

    &:focus,
    &:active {
      color: $color-green-primary;
    }
    &:hover {
      color: $color-green-hover;
    }
  }

  &__drag-drop {
    border-radius: $base-size * 2;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $base-size * 2.5 $base-size * 3;
    border: 2px dashed $color-gray-3;
    color: $color-gray-1;
    transition: all var(--transition);

    &.highlight {
      border-color: $color-gray-1;
      color: $color-black;
    }

    & label {
      @include text-s-regular;

      display: flex;
      align-items: center;
      gap: $base-size/2;

      pointer-events: none;

      & span {
        @media (max-width: $max-m) {
          padding-top: 0.2em;
        }
      }
    }

    & svg {
      width: $base-size * 3;
      height: $base-size * 3;
      margin-right: $base-size/2;
    }

    & span {
      pointer-events: none;
    }

    & input {
      opacity: 0;
      position: absolute;
      overflow: hidden;
      width: 0;
      height: 0;
    }
  }
}

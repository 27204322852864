.partners {
  margin-top: $base-size * 4;

  @media (max-width: $max-m) {
    margin-top: 0;
  }

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $base-size * 10;
    margin-bottom: $base-size * 10;

    &-top {
      position: relative;
    }
  }
  &__bg {
    position: absolute;
    height: 606px;
    top: -$base-size * 2;
    left: -$base-size * 6;
    z-index: 0;
    pointer-events: none;

    & img {
      max-width: none;
      height: 100%;
      width: auto;
      object-fit: contain;
      object-position: left top;
      opacity: 1;
    }
  }
  &__menu {
    display: flex;
    flex-wrap: wrap;
    gap: $base-size * 2.5;
    margin-bottom: $base-size * 6;

    @media (max-width: $max-m) {
      display: none;
    }

    &-item {
      & > a {
        @include text-l-regular;
        color: $color-green-primary;
        cursor: pointer;

        @media (max-width: $max-m) {
          @include text-m-regular;
        }
      }

      &:hover > a,
      &:focus > a {
        color: $color-green-hover;
      }

      &.active {
        & > a {
          color: $color-black;
        }
      }
    }
  }

  &__title {
    margin: $base-size * 2 0 $base-size * 5;
    @include font-h1;

    @media (max-width: $max-l) {
      @include font-h2;
    }
    @media (max-width: $max-m) {
      @include font-h3;
      margin: $base-size * 4 0;
    }
  }
}

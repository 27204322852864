.index {
  background-color: $color-black;
  padding: $base-size * 10.5 0 $base-size * 6;
  display: grid;
  grid-template-columns: subgrid;
  position: relative;
  overflow: hidden;

  @media (max-width: $max-xl) {
    padding: $base-size * 6.25 0 ($base-size * 4.375);
  }
  @media (max-width: $max-l) {
    padding: $base-size * 5.625 0 $base-size * 5;
  }

  &__container {
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: repeat(3, auto);
    // grid-column: 1 / -1;

    // & > * {
    //   grid-column: 1 / -1;
    // }
  }

  &__title {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
    @include font-h1;
    color: $color-white;
    margin-bottom: $base-size * 8;

    @media (max-width: $max-xl) {
      grid-column: 1 / 10;
    }

    @media (max-width: $max-l) {
      grid-column: 1 / -1;
    }

    @media (max-width: $max-m) {
      @include font-h4;
      margin-bottom: $base-size * 4.5;
    }

    & span {
      color: $color-green-primary;
    }
  }

  &__thing {
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    place-self: center;

    @media (max-width: $max-xl) {
      display: none;
    }
  }

  &__phone {
    display: flex;
    gap: $base-size * 3;
    align-items: center;
    transform: translateX(-$base-size * 3.25);
    grid-column: 1 / 6;

    color: $color-gray-4;
    @include text-xs-regular;

    @media (max-width: $max-xl) {
      transform: none;
    }
    @media (max-width: $max-m) {
      grid-column: 1 /-1;
    }

    &:hover {
      color: rgba($color-gray-4, 0.7);

      & .index__phone-icon svg {
        opacity: 0.7;
      }
    }

    &-text {
      width: 300px;
    }

    &-icon {
      & svg {
        z-index: 1;
        position: relative;
        fill: $color-red;
        stroke: none;
      }
      &:before {
        background: #fff;
        color: #3d9d6f;
        z-index: 1;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 590px;
        height: 590px;
        opacity: 0.4;
        background: #cf4656;
        filter: blur(200px);
        z-index: 0;
        pointer-events: none;
      }
    }
  }

  &-content {
    &__container {
      display: flex;
      flex-direction: column;
      gap: $base-size * 10;

      padding-top: $base-size * 10;

      @media (max-width: $max-m) {
        gap: $base-size * 7.5;
        padding-top: $base-size * 5.75;
      }
    }

    &__title {
      // text-wrap: balance;
      @include font-h2;
      max-width: 800px;

      @media (max-width: $max-m) {
        @include font-h4;
      }

      & span {
        color: $color-green-primary;
      }
    }
  }
}

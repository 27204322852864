.product {
  $product-top-bg: $color-gray-6;

  margin-top: $base-size * 4;
  margin-bottom: $base-size * 10;

  & .breadcrumbs {
    @media (max-width: $max-m) {
      display: none;
    }
  }

  & > .container {
    @media (max-width: $max-m) {
      grid-template-columns: calc-container-grid(
        $container-side-margin-catalog,
        $container-gap-small
      );
    }
  }

  &__container {
    @include container-content-grid;

    display: flex;
    flex-direction: column;
    gap: $base-size * 10;
    margin-bottom: $base-size * 15;

    & > * {
      grid-column: 1 / -1;
    }

    @media (max-width: $max-m) {
      margin-bottom: $base-size * 10;
    }

    &-bottom {
      @media (max-width: $max-l) {
        grid-column: 1 / -1;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: stretch;

    border-radius: $base-size * 6;
    background: $product-top-bg;
    // padding: $base-size * 5;

    display: grid;
    grid-template-columns: repeat(16, minmax(0, 1fr));

    @media (max-width: $max-xl) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    @media (max-width: $max-l) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
      gap: $base-size * 4;
    }

    @media (max-width: $max-m) {
      border-radius: $base-size * 4;
    }
  }

  &__goback {
    position: absolute;
    left: $base-size * 2.5;
    top: $base-size * 2.5;
    z-index: 2;

    @media (max-width: $max-m) {
      left: $base-size;
      top: $base-size;
    }
  }

  &__gallery {
    grid-column: 1 / 8;
    overflow: hidden;
    padding: $base-size * 5;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: $base-size * 3;

    @media (max-width: $max-xl) {
      grid-column: 1 / 6;
    }

    @media (max-width: $max-l) {
      grid-column: 1 / -1;
      padding: $base-size * 4;
      padding-bottom: 0;
    }
    @media (max-width: $max-m) {
      padding: $base-size * 2;
      padding-bottom: 0;
    }

    &-images {
      mix-blend-mode: multiply;
      flex: 1 0 auto;
      overflow: hidden;
    }

    &-image {
      user-select: none;

      & > a {
        width: 100%;
        height: 100%;
        display: block;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-thumbs {
      display: flex;
      gap: $base-size;
      flex-wrap: wrap;

      & .swiper-wrapper {
        gap: $base-size;

        @media (max-width: $max-m) {
          gap: $base-size/2;
        }
      }
    }
    &-thumb {
      background: $color-white;
      overflow: hidden;
      width: $base-size * 10;
      height: $base-size * 10;
      border-radius: $base-size * 2;
      // padding: $base-size/2;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      user-select: none;

      @media (max-width: $max-m) {
        height: $base-size/2;
        border-radius: $base-size/4;
        width: $base-size/2;
        padding: 0;
        transition: width var(--transition);
        pointer-events: none;

        background: $color-gray-3;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media (max-width: $max-m) {
          display: none;
        }
      }

      &.swiper-slide-thumb-active {
        @media (max-width: $max-m) {
          width: $base-size * 2.5;
          background-color: $color-gray-2;
        }

        &:before {
          content: '';
          display: block;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border: 2px solid $color-green-primary;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: $base-size * 2;

          @media (max-width: $max-m) {
            display: none;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;

    grid-column: 8 / -1;
    padding: $base-size * 5;

    @media (max-width: $max-xl) {
      grid-column: 6 / -1;
    }
    @media (max-width: $max-l) {
      grid-column: 1 / -1;
      padding: $base-size * 4;
      padding-top: 0;
    }
    @media (max-width: $max-m) {
      padding: $base-size * 2;
      padding-top: 0;
    }

    &-top {
      display: flex;
      flex-direction: column;
      gap: $base-size * 3;
    }
  }

  &__share {
    position: absolute;
    right: $base-size * 2.5;
    top: $base-size * 2.5;

    @media (max-width: $max-m) {
      right: $base-size;
      top: $base-size;
    }
  }

  &__title {
    @include font-h2;
    // margin-bottom: $base-size * 3;
    flex: 0 0 auto;
    padding-right: $base-size * 5;

    @media (max-width: $max-xl) {
      @include font-h3;
    }
    @media (max-width: $max-m) {
      @include font-h4;
    }
  }

  &__attrs {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: $base-size;
  }

  &__attr {
    padding: $base-size * 1.25 $base-size * 1.5;
    background: $color-white;
    border-radius: $base-size;
    color: $color-gray-2;
    display: flex;
    align-items: center;
    gap: $base-size * 0.75;
    @include text-xs-regular;

    @media (max-width: $max-m) {
      gap: $base-size/2;
    }

    &:before {
      content: '';
      display: block;
      width: $base-size * 0.75;
      height: $base-size * 0.75;
      border-radius: 50%;
      background-color: $color-gray-2;
    }

    &--stock {
      color: $color-green-primary;

      &:before {
        background-color: $color-green-primary;
      }
    }
  }

  &__props {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    gap: $base-size;

    @include text-xs-regular;
    color: $color-gray-2;

    @media (max-width: $max-l) {
      margin-top: $base-size * 2;
    }

    & dl {
      display: flex;
      position: relative;

      gap: $base-size/2;
      justify-content: space-between;
      align-items: end;
      // flex-wrap: wrap;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: $base-size/2;
        width: 100%;
        border-bottom: 1px dashed $color-gray-4;
      }

      & span {
        position: relative;
        padding: $base-size/2 0;
        z-index: 1;

        background: $product-top-bg;
      }

      & dt {
        flex: 1 1 auto;
        display: flex;
        gap: $base-size/2;
        max-width: 40%;

        @media (max-width: $max-m) {
          hyphens: auto;
          overflow-wrap: break-word;
          display: inline-block;
        }

        &:after {
          content: '';
          // border-bottom: 1px dashed $color-gray-4;

          flex: 1 0 content;
        }
      }

      & dd {
        flex: 0 1 auto;
        max-width: 60%;
        display: inline-flex;

        @media (max-width: $max-m) {
          hyphens: auto;
          overflow-wrap: break-word;
        }

        & span {
          &:after {
            content: '';
            display: inline-block;
            background: $product-top-bg;
          }
        }
      }
    }

    &--tab {
      margin-bottom: $base-size * 6;
      @include text-s-regular;

      @media (max-width: $max-m) {
        @include text-xs-regular;
      }

      & dl span {
        background: $color-white;
      }
    }

    &-link {
      color: $color-green-primary;
      @include text-s-regular;

      &:hover,
      &:focus {
        color: $color-green-hover;
      }
    }
  }

  &__action {
    flex: 0 0 auto;
    display: flex;
    gap: $base-size * 1.5;
    justify-content: end;
    width: 100%;

    @media (max-width: $max-m) {
      flex-direction: column;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(16, minmax(0, 1fr));
    gap: $base-size;

    @media (max-width: $max-xl) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    @media (max-width: $max-l) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
      gap: $base-size * 6;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
    padding-right: $base-size * 4;

    grid-column: 1 / 11;

    @media (max-width: $max-xl) {
      grid-column: 1 / 9;
    }
    @media (max-width: $max-l) {
      grid-column: 1 / -1;
      padding-right: 0;
    }

    &-list {
      display: flex;
      gap: $base-size * 4;

      @media (max-width: $max-m) {
        margin: 0 $container-side-margin-catalog * -1;
        padding: 0 $container-side-margin-catalog;
        overflow-x: scroll;
        overflow-y: hidden;
      }
    }
    ::-webkit-scrollbar {
      height: 0;
    }
  }

  &__tab {
    &-link {
      & a {
        @include font-h4;
        color: $color-gray-3;
      }
      &.active {
        & a {
          color: $color-black;
        }
      }
    }

    &-item {
      @include text-s-regular;
      display: none;

      &.active {
        display: block;
      }

      & p,
      & li,
      & table {
        @include text-s-regular;

        @media (max-width: $max-m) {
          @include text-xs-regular;
        }
      }
    }
  }

  &__side {
    grid-column: 11 / -1;

    @media (max-width: $max-xl) {
      grid-column: 9 / -1;
    }
    @media (max-width: $max-l) {
      grid-column: 1 / -1;
    }

    &-panel {
      display: flex;
      flex-wrap: wrap;
      gap: $base-size * 2.5;
      align-items: center;
      padding: $base-size * 3;
      background: $color-gray-6;
      border-radius: $base-size * 4;

      &-title {
        @include font-h3;
        flex: 1 1 auto;

        background: linear-gradient(277deg, #27bf71 8.83%, #367497 102.46%);
        background-clip: text;
        background-size: 150px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-icon {
        flex: 0 0 auto;
      }
      &-text {
        flex: 1 0 auto;
        width: 100%;

        @include text-xs-regular;

        & * {
          @include text-xs-regular;
        }
      }
    }
  }
}

.main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;
  // position: relative; //fix choices dropdown being cut by footer
  background-color: $color-white;
  // overflow: hidden; // disable for sticky to work
  // overflow-x causes vertical scroll on events__list // disabled for negative margin on index slider

  &__container {
    position: relative;
    max-width: 100vw;
  }

  &-footer-wrap {
    max-width: 100vw;
    overflow: hidden;
  }
}

.container {
  @include container;
}
.container-16 {
  @include container-16;
}

// Transparent columns helper
.columns {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $column-margin;
}
.column {
  background-color: $color-gray-4;

  &:nth-child(2n) {
    // background-color: $color-gray-2;
  }
}

.column-helper {
  position: absolute;
  height: 7000px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $column-margin;
  opacity: 0.1;
  pointer-events: none;
  z-index: 100;

  @media (max-width: $max-xl) {
  }
}
.column-helper__column {
  background-color: $color-gray-4;
  height: 100%;
}

$grid-item-size: $base-size * 24;
$item-size: $base-size * 16.25;

.thing {
  display: flex;
  justify-content: center;
  align-items: center;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, $grid-item-size); //minmax(0, 1fr)
    grid-template-rows: repeat(3, $grid-item-size);

    &-item {
      width: $grid-item-size;
      height: $grid-item-size;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__item {
    width: $item-size;
    height: $item-size;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 384px;
      height: 384px;
      border-radius: 50%;
      border: 1px solid #384049;
      z-index: 0;
      pointer-events: none;

      .thing--light & {
        border-color: $color-gray-5;
      }
    }

    &-image {
      width: $base-size * 9.5;
      height: $base-size * 9.5;
      border-radius: 50%;
      overflow: hidden;
      scale: 0;
      z-index: 3;
      user-select: none;

      transition: scale 0.35s var(--easing);

      .thing__item:hover & {
        scale: 0.63;
      }
      .thing__item.active & {
        scale: 1;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-blurred-circle {
      position: absolute;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(68, 215, 179, 0.1) 58%,
        #44d7b3 100%
      );
      width: $base-size * 30;
      height: $base-size * 30;
      transform: scale(0.1);
      border-radius: 50%;
      box-sizing: content-box;
      pointer-events: none;
      opacity: 0;
      filter: blur(20px);

      z-index: 2;

      transition: all 0.4s var(--easing) 0.1s;

      .thing__item:hover & {
        transform: scale(1);
        border-color: transparent;
        filter: blur(6px);
        opacity: 0.25;

        transition:
          all 0.7s var(--easing-wave) 0.1s,
          opacity 0.5s ease 0.15s;
      }
    }

    &-dot {
      position: absolute;
      background: $color-green-primary;
      border: 4px solid $color-black;
      width: $base-size * 1.5;
      height: $base-size * 1.5;
      border-radius: 50%;
      box-sizing: content-box;
      pointer-events: none;
      opacity: 1;
      transform: scale(1);

      z-index: 2;

      transition: all 0.3s ease 0.3s;

      .thing--light & {
        border-color: $color-white;
      }

      .thing__item:hover & {
        // background-color: rgba($color-green-primary, 0);
        filter: blur(6px);
        opacity: 0.25;
        transform: scale(0);

        transition: all 0.3s ease 0s;
      }
    }

    &-progress {
      position: absolute;
      width: $base-size * 16.25;
      height: $base-size * 16.25;
      // border: 2px solid $color-green-primary;
      border-radius: 50%;

      transition: scale 0.4s var(--easing) 0.1s;
      scale: 0;

      .thing__item:hover & {
        transition: scale 0.45s var(--easing-slide) 0.05s;

        scale: 1;
      }
      .thing__item.active & {
        transition: scale 0.6s var(--easing-slide) 0.05s;

        scale: 1;
      }
    }

    &-label {
      position: absolute;
      z-index: 3;

      background: $color-red;
      color: $color-white;
      display: inline-flex;
      padding: $base-size * 1.25 $base-size * 2.5;
      justify-content: center;
      align-items: center;
      border-radius: $base-size * 2.75 $base-size * 2.75 0 $base-size * 2.75;

      $translate: $base-size * 3;
      transform: translate(
        calc(-50% - #{$translate}),
        calc(-50% - #{$translate})
      );

      opacity: 0;
      scale: 0.5;

      transition:
        scale 0.5s ease 0.1s,
        opacity 0.2s ease 0.1s;

      // .thing__item.active &,
      .thing__item:hover & {
        opacity: 1;
        scale: 1;

        transition:
          scale 0.5s ease 0.3s,
          opacity 0.4s ease 0.4s;
      }
    }
  }
}

@property --progress {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}
@property --progress-5 {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

:root {
  --progress: 0;
  --progress-5: 0;
  --delay: 5s; // default slider delay
}

.circular-progress {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 2px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);

  // --progress: 0;
  opacity: 0;
  transition:
    --progress 0.05s linear 0.1s,
    --progress-5 0.05s linear 0.1s,
    opacity 0.1s linear;

  .thing__item.active & {
    transition:
      --progress var(--delay) linear 0s,
      --progress-5 var(--delay) linear 0s,
      opacity 0.1s linear;
  }

  .thing__item.change & {
    // visibility: hidden;
    opacity: 0 !important;
    transition:
      --progress 0.05s linear 0s,
      --progress-5 0.05s linear 0s,
      opacity 0s linear;
  }
}

.thing__item:not(.active) .circular-progress {
  --progress: 0;
  --progress-5: 0;
  // transition:
  //   --progress 0.1s linear 0s,
  //   opacity 0.1s linear;
}

.thing__item:hover:not(.active) .circular-progress {
  // --progress: 0;
}

.thing__item:hover .circular-progress,
.thing__item.active .circular-progress {
  opacity: 1;
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: calc(var(--circumference) - var(--dash)) var(--dash);

  transition: stroke-dasharray 0s linear 0s;
  stroke: $color-green-primary;
}

@keyframes progress-animation {
  from {
    --progress: 0;
  }
  to {
    --progress: 100;
  }
}

.index-5 {
  .circular-progress {
    --dash: calc((var(--progress-5) * var(--circumference)) / 100);
  }
}

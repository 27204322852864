.preview {
  justify-content: flex-start;

  &__header {
    display: flex;
    justify-content: center;
    height: $base-size * 7;
    align-items: center;
  }

  &__pages {
    max-width: 640px;
    margin: 0 auto;
    padding-left: $base-size * 3;
  }

  &__page {
    margin-bottom: $base-size/2;
    @include text-m-medium;
    & a {
      color: $color-green-primary;

      &:hover,
      &:focus {
        color: $color-green-hover;
      }
    }
  }

  &__main {
    padding: $base-size * 6 0;
    background: $color-gray-6;

    flex: 1 0 auto;
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: $base-size * 3;
    margin-bottom: $base-size * 6;
  }

  &__bg {
    position: relative;
    height: 500px;
  }

  & .section {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
}

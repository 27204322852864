@mixin gradient-bg {
  width: 100%;
  height: 100%;

  //w 1200
  // h 700

  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
  background: linear-gradient(209deg, #27bf71 17.72%, #0f7d55 90.4%);

  &__1,
  &__2,
  &__3 {
    position: absolute;
    width: 520px; //43%;
    height: 520px; // 0;
    // padding-bottom: 43%;
    border-radius: 50%;

    transition: all 2.8s var(--easing-bg) 0.2s;
  }

  &__1 {
    right: 0;
    top: 0;
    transform: $header-bg-1-translate;
    filter: blur(160px);
    // opacity: 0.7;
    background: #156ef0;
    transition-delay: 0.15s;
  }

  &__2 {
    left: 0;
    top: 0;
    transform: $header-bg-2-translate;
    filter: blur(134px);
    background: #01d67d;
    transition-delay: 0.1s;
  }

  &__3 {
    // padding-bottom: 33%;
    height: 452px;
    right: 0;
    top: 100%;
    transform: $header-bg-3-translate;
    opacity: 0.7;
    filter: blur(80px);
    background: #01d67d;
    transition-delay: 0.2s;
  }
}

.gradient-bg {
  @include gradient-bg();
}

$offcanvas-top-height: $base-size * 12.5;
$offcanvas-gap: $base-size * 4;

html.offcanvas-opened {
  overflow: hidden;
  // height: 100dvh;

  & body {
    height: 100dvh;
    overflow: hidden;
  }
}

.offcanvas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 200;
  pointer-events: none;

  visibility: hidden;
  opacity: 0;

  transition:
    opacity var(--transition),
    visibility var(--transition);

  html.offcanvas-opened &,
  &.opened {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }

  & a:hover,
  & a:focus,
  & a:active {
    color: $color-white;
    opacity: 0.8;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $offcanvas-gap;
    height: 100%;

    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__top {
    z-index: 1;
    // position: relative;

    // padding: $base-size * 15 $base-size * 3 $base-size * 3 $base-size * 3;
    color: $color-white;
    // height: $offcanvas-top-height;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__back {
    @include button-reset;
    width: $base-size * 3;
    height: $base-size * 3;
    padding: $base-size * 2.75;
    box-sizing: content-box;

    opacity: 0;
    pointer-events: none;
    cursor: pointer;

    transition: opacity var(--transition);

    .offcanvas.active-level-1 &,
    .offcanvas.active-level-2 & {
      opacity: 1;
      pointer-events: all;
    }

    & svg {
      stroke: $color-white;
    }
  }

  &__close {
    @include button-reset;
    width: $base-size * 6;
    height: $base-size * 6;
    padding: $base-size * 2;
    box-sizing: content-box;
    cursor: pointer;

    fill: $color-white;
  }

  &__middle {
    z-index: 1;
    flex: 1 0 auto;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: $base-size * 4;
    // padding: $base-size * 15 $base-size * 3 $base-size * 3 $base-size * 3;
    padding: $base-size * 0 $base-size * 3;

    transition: transform var(--transition);

    &:has(&-item.active-level-1) {
      transform: translateX(-100%);
    }
    &:has(&-item.active-level-2) {
      transform: translateX(-200%);
    }

    &-item {
      &-title {
        @include font-h3;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }

      &.parent &-title {
        &:after {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 18L16 12L10 6' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          flex: 0 0 auto;
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }

    &-sub {
      position: absolute;
      width: 100vw;
      // height: calc(100dvh - #{$offcanvas-top-height} - #{$offcanvas-gap});
      left: 100%;
      top: 0;
      // padding: $base-size * 15 $base-size * 3 $base-size * 3 $base-size * 3;
      padding: $base-size * 0 $base-size * 3 $base-size * 3 $base-size * 3;

      display: flex;
      flex-direction: column;
      gap: $base-size * 4;
      display: none;
      color: $color-white;

      .offcanvas__menu-item.active-level-1 & {
        // left: 0;
        display: flex;
      }

      &-heading {
        @include font-h3;
        margin-bottom: $base-size / 2;
      }

      &-item {
        &-title {
          @include font-h5;
          color: $color-white;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.parent &-title {
          &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 18L16 12L10 6' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            flex: 0 0 auto;
            width: 24px;
            height: 24px;
            display: block;
          }
        }
      }
    }

    &-last {
      // display: none;

      position: absolute;
      width: 100vw;
      // height: calc(100dvh - #{$offcanvas-top-height} - #{$offcanvas-gap});
      left: 100%;
      top: 0;
      padding: $base-size * 0 $base-size * 3 $base-size * 3 $base-size * 3;
      display: flex;
      flex-direction: column;
      gap: $base-size * 4;

      display: none;
      color: $color-white;

      .offcanvas__menu-sub-item.active-level-2 & {
        display: flex;
      }

      &-heading {
        @include font-h3;
        margin-bottom: $base-size/2;
      }

      &-item {
        &-title {
          color: $color-white;
          @include font-h5;
        }
      }
    }
  }

  &__bottom {
    z-index: 1;
    position: relative;
    padding: $base-size * 3 0 $base-size * 3 $base-size * 3;
    color: $color-white;
    flex: 0 0 auto;
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.3s ease 0.3s;

    .offcanvas.active-level-1 &,
    .offcanvas.active-level-2 & {
      opacity: 0;
      pointer-events: none;
      // height: 0;
      // padding: 0;
      // visibility: hidden;

      display: none;

      transition:
        opacity 0.3s ease,
        visibility 0s linear 0.3s;
    }
  }

  &__phone {
    margin-bottom: $base-size * 2.5;
    display: block;
    @include font-h4;
    color: $color-white;
  }
  &__schedule {
    margin-bottom: $base-size * 5;

    @include font-h5;
    opacity: 0.6;
    color: $color-white;
  }
  &__email {
    display: block;
    @include text-m-regular;
    color: $color-white;
  }
}

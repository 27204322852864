@mixin button($button-height: $button-height-l) {
  color: $color-white;
  border: none;
  box-sizing: border-box;
  display: inline-flex;
  gap: $base-size * 2;
  align-items: center;
  padding: 21px $base-size * 4;
  position: relative;
  cursor: pointer;
  flex: 0 0 auto;
  outline: none;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;

  @include text-s-medium;
  transition: all var(--transition);

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  & svg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: $base-size * 2;
    flex: 0 0 auto;
    box-sizing: content-box;
    transition: all var(--transition);
    position: relative;
    z-index: 1;
    opacity: 1;
    pointer-events: none;
  }

  &:hover svg,
  &:focus svg {
    opacity: 1;
  }

  & > span {
    position: relative;
    z-index: 1;
    flex: 1 0 auto;
    padding-top: 0.1em;

    @media (max-width: $max-m) {
      padding-top: 0.2em;
    }
  }
}

@mixin button-gradient() {
  background: radial-gradient(84.93% 84.93% at 81.7% 7.03%, #1cd889 0%, #23aa7a 63.5%, #337fd8 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    transition: opacity var(--transition);
    background: var(
      --Radial-Hover,
      radial-gradient(103.12% 103.12% at 81.7% 7.03%, #0fda85 0%, #2eb384 63.5%, #4690e7 100%)
    );
  }

  & svg {
    stroke: $color-white;
  }
  &:hover {
    box-shadow: 0px 12px 30px 0px #0f475804;

    &:before {
      opacity: 1;
    }

    color: $color-white;
  }
}

@mixin button-size($button-height: $button-height-l) {
  height: $button-height;
  border-radius: $button-height / 2;

  @if $button-height == $button-height-m {
    @include text-xs-medium;
  }

  &:before {
    border-radius: $button-height / 2;
  }

  & svg {
    padding: if(
      $button-height == $button-height-l,
      $base-size * 2,
      if($button-height == $button-height-m, $base-size * 3/4, $base-size * 2)
    );
  }
}

@mixin button-outline {
  background-color: transparent;
  color: $color-black;
  padding: 4px 4px 4px $base-size * 4;
  box-shadow: 0px 12px 30px 0px rgba(255, 255, 255, 0);

  & svg {
    stroke: $color-gray-1;
  }

  &::before {
    border: 1px solid rgba($color-gray-5, 0.7);
  }

  &:hover,
  &:focus,
  &:active {
    background: $color-white;
    color: $color-black;
    box-shadow: 0px 12px 30px 0px #0f475814;
  }
}
@mixin button-icon {
  padding: 0;
  & svg {
    stroke: $color-gray-3;
  }
}

.button {
  @include button;
  @include button-size($button-height-l);

  &--gradient {
    @include button-gradient;

    &.button--icon {
      & svg {
        stroke: $color-white;
      }
    }
  }

  &--icon {
    @include button-icon();
    @include button-size($button-height-icon);
    display: inline-block;
  }

  &--white {
    background: $color-white;
    color: $color-green-primary;

    &:hover {
      background: $color-white;
      color: $color-green-hover;
      box-shadow: 0px 12px 30px 0px #0f475814;
    }
  }

  &--m {
    @include button-size($button-height-m);
    // @include text-xs-medium;

    &.button--arrow {
      padding-left: $base-size * 2.5;
    }

    // & svg {
    //   padding: $base-size * 3 / 4;
    // }
  }

  &--s {
    @include button-size($base-size * 4.5);

    & svg {
      padding: $base-size * 3 / 4;
    }
  }
  &--outline {
    @include button-outline;
  }

  &--close {
    @include button-icon();
    background-color: $color-white;

    &:hover {
      background-color: $color-gray-2;

      & svg {
        opacity: 0.6;
      }
    }
    &:active {
      background-color: $color-gray-3;
    }
  }

  &--arrow {
    @include button-icon();
    @include button-outline();

    & svg {
      stroke: $color-white;
    }
    & span {
      text-align: left;
    }
  }

  &__icon {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 auto;

    @include button-gradient();

    transition: all var(--transition);
    .button:hover &:before {
      opacity: 1;
    }
  }

  &__text {
    position: relative;
    z-index: 1;
  }
}
